import React from 'react';
import icon from '../Assets/rankImage.png'

function RankLine(props){
    let userName = props.data.user_name.replace('@',' ').replace('.',' ')
    if(userName.length > 20){
        let splitted = userName.split(' ')
        splitted.pop()
        userName = splitted.join(' ')
    }
  return (
    <div className="col-12"  style={{fontSize:"0.8rem",fontFamily:(props.data.me?'GothamRoundedBold':'GothamRoundedBook'),color:(props.data.me?'#000000':'#888888')}} >
        <div className="row" style={{margin:'10px 0px',borderBottom:'1px solid #888)',borderColor:'#888',paddingBottom:'8px'}}>
            <div className="col-1"><img src={icon} style={{width:'25px',height:'25px'}} alt={props.data.position}></img></div>
            <div className="col-8" style={{paddingLeft:'20px',paddingTop:'3px'}}>{props.data.position}. {userName}</div>
            <div className="col-2">{Math.floor(props.data.total/60000)}</div>
        </div>
    </div>
  );
}

export default RankLine;