
import React from 'react';
import Api from '../Models/Api';
import Storage from '../Models/Storage'
import Loader from '../Components/Loader'
import { isMobile } from 'react-device-detect';
import AnalyticsData from '../Components/AnalyticsData'
import DonutGraph from '../Components/DonutGraph'
import Caches from '../Models/Caches';
import Paper from '@material-ui/core/Paper';
import BarGraph from './BarGraph';
import Ranking from './Ranking';

const pjson = require('../version.json');

class PracticalResume extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
                  isLoading:true,
                  showData:false,
                  allMetrics:{},
                  firstMetric:{},
                  secondMetric:{},
                  thirdMetric:{},
                  metaDays:this.props.user.meta_days * 1,
                  metaMinutesDay:Math.floor(this.props.user.meta_minutes * 1 / 60000),
                  metaMinutesWeek:Math.floor(this.props.user.meta_minutes * 1 / 60000 * this.props.user.meta_days ),
                  daysDone:{},
                  minutesDay:{},
                  minutesWeek:{},
                  lastSevenDaysMinutes:[],
                  lastMonthDaysMinutes:[],
                  rankData:{},
                  graphOneCT:'45%',
                  graphTwoCT:'35%',
                  graphThreeCT:'30%',
                  metricsStyle: {width:(this.props.isHome?'21vh':"32vh"),height:(this.props.isHome?'16vh':"26vh"),float:"left",transform:"rotate(180deg)",marginTop:"50px",marginLeft:"50px"},
                  graphColors: ['#abffc1','#65ffff','#024E5E','#DDFFE2'],
                  graphBgColors: ['#DDFFE2','#e1ffff','#cedcdf','#DDFFE2'],
                  dow:["dom","seg","ter","qua","qui","sex","sab"],
                  maskOneData:{},
                  maskTwoData: {},
                  maskThreeData: {},
                  elementHeight: (this.props.isHome?'22vh':"30vh"),
                  ranking:[],
                  useRank: false
                };
  }

  

  componentDidMount() {
    this.mountData()  
    
    setInterval(() => {
      let theHeight = document.getElementsByClassName('execLessonCard')[0]?.clientHeight
      if(!!theHeight) this.setState({elementHeight:theHeight})
    }, 1000);
  
  }

  componentDidUpdate(prevProps) {
    if(this.props.user!==prevProps.user){
      this.mountData()
    }
  } 

 
  

  mountData(){
    
    let weightOne = 0.1
    let weightTwo = 0.1
    if(isMobile){
      weightOne = 0.3
      weightTwo = 0.4
      this.setState({'graphOneCT':'25%'})
      this.setState({'graphTwoCT':'25%'})
      this.setState({'graphThreeCT':'20%'})
      this.setState({'metricsStyle':{width:"38vw",height:"38vw",position:"absolute",right:"0px",top:"50px",transform:"rotate(180deg)"}})
     }
     
     const graphsGeneralOptions = {
                                plugins:{ legend: { display: false }, tooltip: { enabled: false } },
                                legend: {
                                  display: false
                                },
                                tooltip: {
                                  enabled: false,
                                  
                                }
                              }
    this.setState({'maskOneData':{
      datasets: [
        {
          data: [100],
          cutout: this.state.graphOneCT,
          options: graphsGeneralOptions,
          backgroundColor: [
            this.state.graphBgColors[0],this.state.graphBgColors[0]
          ],
          borderColor: [
            this.state.graphBgColors[0],this.state.graphBgColors[0]
          ]
        }, 
        { 
          weight: weightOne
        },
        {
          data: [100],
          options: graphsGeneralOptions,
          cutout: this.state.graphTwoCT,
          backgroundColor: [
            this.state.graphBgColors[1],this.state.graphBgColors[1]
          ],
          borderColor: [
            this.state.graphBgColors[1],this.state.graphBgColors[1]
          ]
        }, 
        { 
          weight: weightTwo
        },
        {
          data: [100],
          options: graphsGeneralOptions,
          cutout: this.state.graphThreeCT,
          backgroundColor: [
            this.state.graphBgColors[2],this.state.graphBgColors[2]
          ],
          borderColor: [
            this.state.graphBgColors[2],this.state.graphBgColors[2]
          ]
        }
      ],
    }})
      
    try{
        Caches().then(async (result)=>{
          this.setState({'dow':result.lang.dow})
          const session = this.props.user
          this.setState({'useRank':(session.ranked==="true")})
          return session
        }).then(async (session)=>{
          let today = new Date().toLocaleString().split(' ')[0].split('/').reverse().join('-').replace(',','')
          const token = await Storage.get('token')
          const mtD = session.meta_days * 1
          const mtMd = Math.floor(session.meta_minutes * 1 / 60000)
          const mtMw = Math.floor(mtD * mtMd)
          this.setState({'metaDays':mtD})
          this.setState({'metaMinutesDay':mtMd})
          this.setState({'metaMinutesWeek':mtMw})
          const metas = []
          metas['minutesWeek'] = 0
          metas['minutesDay'] = 0
          return await Api.get('/analytics',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{            
            this.setState({'isLoading':false})
            const results = result.data
            this.setState({'allMetrics':results})
            let dowData = [0,0,0,0,0,0,0]
            for(let i=0;i<results.weekly.length;i++){
              metas['minutesWeek'] += results.weekly[i].amount
              if(results.weekly[i].day  === today){
                metas['minutesDay'] = results.weekly[i].amount
              }
              dowData[i] = Math.floor(results.weekly[i].amount / 60000)
            }
            const graphData = {
              labels: this.state.dow,
              datasets:[{
                label:'',
                data:dowData,
                backgroundColor: [
                  '#ABFFC1',
                  '#70E781',
                  '#ABFFC1',
                  '#70E781',
                  '#ABFFC1',
                  '#70E781',
                  '#ABFFC1'
                ]
              }]
            }
           
            this.setState({'lastSevenDaysMinutes':graphData}) 
            
            let domData = []
            let monthlyLabels = []
            let monthlyColors = []
            let colorOptions = ['#ABFFC1','#70E781']
            let colorAp = 0
            for(let i=0;i<results.lastmonth.length;i++){
              domData[i] = Math.floor(results.lastmonth[i].amount / 60000)
              monthlyLabels[i] = results.lastmonth[i].day.split('-')[2]+'/'+results.lastmonth[i].day.split('-')[1]
              monthlyColors[i] = colorOptions[colorAp]
              colorAp++
              if(colorAp>1) colorAp=0
            }
            
            const graphMData = {
              labels: monthlyLabels,
              datasets:[{
                label:'',
                data:domData,
                backgroundColor: monthlyColors
              }]
            }
            this.setState({'lastMonthDaysMinutes':graphMData}) 
            let dpt = results.dayswithpratice_week_total
            let minD = Math.floor(metas['minutesDay'] / 60000)
            let minW = Math.floor(metas['minutesWeek'] / 60000)
            
            this.setState({'daysDone':dpt})
            this.setState({'minutesDay':minD})
            this.setState({'minutesWeek':minW})
            
            if(dpt>mtD) dpt = mtD
            if(minW>mtMw) minW=mtMw
            if(minD>mtMd) minD=mtMd
            
            
            this.setState({'firstMetric':{
                    datasets: [
                      {
                        data: [dpt,(this.state.metaDays-dpt)],
                        options: graphsGeneralOptions,
                        cutout: this.state.graphOneCT,
                        rotation: 180,
                        elements: {
                          arc: {
                            borderWidth: 0,
                            borderRadius: (dpt!==mtD&&dpt>0?100:0),
                          },
                        },
                        backgroundColor: [
                          this.state.graphColors[0],this.state.graphBgColors[0]
                        ],
                        borderColor: [
                          this.state.graphColors[0],this.state.graphBgColors[0]
                        ]
                      }, 
                      { 
                        weight: weightOne
                      },
                      {
                        data: [minW,(this.state.metaMinutesWeek-minW)],
                        options: graphsGeneralOptions,
                        cutout: this.state.graphTwoCT,
                        rotation: 180,
                        elements: {
                          arc: {
                            borderWidth: 0,
                            borderRadius: (mtMw!==minW&&minW>0?100:0),
                          },
                        },
                        backgroundColor: [
                          this.state.graphColors[1],this.state.graphBgColors[1]
                        ],
                        borderColor: [
                          this.state.graphColors[1],this.state.graphBgColors[1]
                        ]
                      }, 
                      { 
                        weight: weightTwo
                      },
                      {
                        data: [minD,(this.state.metaMinutesDay-minD)],
                        options: graphsGeneralOptions,
                        cutout: this.state.graphThreeCT,
                        rotation: 180,
                        elements: {
                          arc: {
                            borderWidth: 0,
                            borderRadius: (mtMd!==minD&&minD>0?100:0),
                          },
                        },
                        backgroundColor: [
                          this.state.graphColors[2],this.state.graphBgColors[2]
                        ],
                        borderColor: [
                          this.state.graphColors[2],this.state.graphBgColors[2]
                        ]
                      }
                    ],
              }})

              if(this.props.withGraphs){
                Api.get('/ranking',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
                  this.setState({'ranking':result.data.ranking})
                })
              }
              this.setState({'showData':true})
          }).catch(function (error) {
            console.log('Something goes wrong',error)
          })
        }) 
      } catch(e){
        console.log('Error',e)
      }
  }

  getExtraGraphs(){
      if(this.props.withGraphs===true){
          if(this.state.showData){
            return (
              <>
              { isMobile &&
                <div className='row'>
                  <div className='col-lg-12 col-xs-12'>
                    <BarGraph graphStyle={{marginTop:'25px'}} title={this.props.lang.minutesLastSevenDays} canvasId="last7daystime" graphData={this.state.lastSevenDaysMinutes} /> 
                  </div>
                  <div className='col-lg-12 col-xs-12'>
                    <BarGraph graphStyle={{marginTop:'25px'}} title={this.props.lang.minutesLastMonth} canvasId="last7daystime" graphData={this.state.lastMonthDaysMinutes} /> 
                  </div>
                {this.getRanking()}
                </div>
              }
              
              { !isMobile &&
              <>
               <div className='col-lg-12 col-xs-12'>
                  <BarGraph graphStyle={{marginTop:'25px'}} title={this.props.lang.minutesLastSevenDays} canvasId="last7daystime" graphData={this.state.lastSevenDaysMinutes} /> 
                </div>
                <div className='col-lg-12 col-xs-12'>
                  <BarGraph graphStyle={{marginTop:'25px'}} title={this.props.lang.minutesLastMonth} canvasId="last7daystime" graphData={this.state.lastMonthDaysMinutes} /> 
                </div>
              </>
              }
            </>)
          }
      }
  }

   
  getPaper(){
    return (
      <Paper id="practialResume" className={"col-lg-12 col-xs-12 col-sm-12"+(isMobile?'':' cardList')} style={{height:(isMobile?"30vh":(this.props.isHome?'22vh':"40vh")),display:"flex",flexDirection:"row",justifyContent:"left",alignItems:"center",border:(this.props.isHome?'':"1px solid #aaa")}}>
        <div>
        <h2 className="SessionTitle">{this.props.lang.praticeResumeTitle}</h2>
            <AnalyticsData isHome={this.props.isHome} 
                            isMobile={isMobile} 
                            colors={this.state.graphColors} 
                             metrics={
                                    {
                                    metaDays:this.state.metaDays,
                                    metaMinutesDay:this.state.metaMinutesDay *1, 
                                    metaMinutesWeek:this.state.metaMinutesWeek,
                                    daysDone:this.state.daysDone,
                                    minutesDay:this.state.minutesDay,
                                    minutesWeek:this.state.minutesWeek
                                    }
                            }
                            lang={this.props.lang}
            />
          </div>
          <div>
            <DonutGraph isHome={this.props.isHome} isMobile={isMobile} canvasId="outer" graphData={this.state.firstMetric} graphStyle={this.state.metricsStyle}/>
          </div>
        </Paper>
    )
  }

  getRanking(){
    return (
        <div className='col-xs-12 col-sm-12 col-lg-6' style={{marginTop:'25px',visibility:(this.state.useRank?"visible":"hidden"),display:(this.state.useRank?"block":"none")}}>
          <Ranking rankData={this.state.ranking} lang={this.props.lang} />
        </div>
    )
  }
  
    render(){
      if(this.state.isLoading || !this.state.showData) return ( <Loader />)
      if(!this.state.isLoading && this.state.showData){
        
      return (  
        <div className={(this.props.isHome?'col-lg-8 col-xs-12 col-sm-12 homeList':'row cardList')}>
            
            { isMobile && 
            <>
            {this.getPaper()}
            { !this.props.isHome && this.getExtraGraphs() }
            </>
            }
            { !isMobile &&
                <div className={(!this.props.isHome?'row cardList':'col-12')}>
                  <div className={(!this.props.isHome?"col-6":"")}>
                  {this.getPaper()}
                  { !this.props.isHome && this.getExtraGraphs() }
                  </div>
                  { !this.props.isHome &&
                  <div className="col-lg-6">{this.getRanking()}</div>
                  }
                </div>
            }
            
            
        </div>
    )
    }
    
  }
  
  
}

export default PracticalResume;