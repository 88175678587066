import React from 'react';
import { isMobile } from 'react-device-detect';
import picture from '../Assets/icone_carinha.png'

function EvaluationLine(props){
  if(props.evaluation.comment+''!=='null' && props.evaluation.comment+''!==''){
    let userName = props.evaluation.name.replace('@',' ').replace('.',' ')
    if(userName.length > 20){
        let splitted = userName.split(' ')
        splitted.pop()
        userName = splitted.join(' ')
    }
    let userPic = picture
    if(!!props.evaluation.avatar){
      userPic = props.evaluation.avatar
    }

    function formatDate(date){
      date = date.split(' ')
      let mins = date[1].split(':')
      let realDate = date[0].split('-').reverse().join('/')
      return realDate+' '+props.lang.aGravePlural+' '+ mins[0]+':'+mins[1]
    }

   return (
      <div className="row" style={{margin:'10px 0px',borderBottom:'1px solid #888)',borderColor:'#888',paddingBottom:'8px'}}>
          <div className="col-lg-1 col-4 text-right" style={{paddingLeft:'10px',minWidth:'100px'}}>
            <img src={userPic} style={{width:"80px",height:"80px",marginRight:"0px"}} alt={userName} />
          </div>
          <div className="col-lg-5 col-8" style={{paddingLeft:'0px',paddingTop:'15px'}}>
            <h6 style={{fontSize:"1.2rem",color:"#666",fontWeight:"1000"}}>{userName} <small style={{fontSize:"0.65rem",fontWeight:"500"}} >{props.lang.atPre} {formatDate(props.evaluation.created_at)}</small></h6>
            {props.evaluation.comment}
            
          </div>
          { isMobile &&
          <div className="col-12"><hr></hr></div>
          }
      </div>
  );
  }
    
}

export default EvaluationLine;