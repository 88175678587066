const Storage = {
    async get(item){
        const parsed = await localStorage.getItem(item)
        if(!typeof parsed!=='undefined' && parsed!==null) return JSON.parse(parsed)
        this.set(item,'{}')
        return {}
    },
    
    async set(item,value){
        return await localStorage.setItem(item,JSON.stringify(value))
    },
    
    async del(item){
        return await localStorage.removeItem(item)
    },

    async clear(){
        return await localStorage.clear()
    },
    
}


export default Storage