import React from 'react';
import Api from '../Models/Api';
import Storage from '../Models/Storage'
import Loader from '../Components/Loader'
import Caches from '../Models/Caches';
import CategoriesCard from '../Components/CategoriesCard'
import CategoryHeader from '../Components/CategoryHeader';

const pjson = require('../version.json');

class Categorias extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
                  isLoading:true,
                  lessonList:[],
                  activeCategory:0,
                  theActiveCategory:{},
                  lang:{}
    }
  }

  componentDidMount(){
    Caches().then(async (result)=>{
      this.setState({'lang':result.lang})
      const token = await Storage.get('token')
      return token
    }).then(async (token)=>{
      this.getData(token)
    });
    document.querySelectorAll('#zapBtn').forEach(link => {
      link.classList.remove('hidden');
    });
  }
  
  getData(token){
    Api.get('/categories',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
      this.setState({'categoryList':result.data})
      this.setState({'activeCategory':result.data[0].id})
      this.setState({'theActiveCategory':result.data[0]})
      this.setState({'isLoading':false})
    }).catch(function (error) {
      console.log('Something goes wrong',error)
    })
  }

  changeActiveCategory(item){
    this.setState({'activeCategory':item.id});
    this.setState({'theActiveCategory':item});
    window.scrollTo(0, 0)
  }
  
  render(){
  if(this.state.isLoading) return ( <Loader />)
  if(!this.state.isLoading){
    return (  
        <div>
        <CategoryHeader lang={this.state.lang} category={this.state.theActiveCategory} />
          <div  className="row cardList">
          {this.state.categoryList.map((item) => <div key={item.id+"_holder"} style={{visibility:(this.state.activeCategory===item.id?"hidden":"visible"),display:(this.state.activeCategory===item.id?"none":"block")}} className="col-xs-12 col-lg-3" onClick={()=>{this.changeActiveCategory(item)}}><CategoriesCard lang={this.state.lang} hideme={(this.state.activeCategory===item.id)} key={item.id} category={item} showNames={true} lesser={true} nolink={true} /></div>)}
          </div>
        </div>
    )
  }
  
  }
}

export default Categorias;