import React from 'react';

import logo from '../Assets/loader.gif'

function Loader(props){
  return (
    <div className="App" style={{backgroundColor:"#abffc1",zIndex:1999}}>
        <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        </header>
  </div>
  );
}

export default Loader;