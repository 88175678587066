const Functions = {
    inArray(haystack,needle){
        if (!haystack || typeof haystack === 'undefined') return false
        for(let i=0;i<haystack.length;i++){
            if(haystack[i]+''===needle+'' || haystack[i]===needle) return true
        }
        return false
    },
    sleep(time){
        return new Promise(async function(resolve,reject){
            window.setTimeout(()=>{
                resolve(true)
            },time)
        })
    }
}

export default Functions