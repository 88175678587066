import React from 'react';
import { createRoot } from 'react-dom/client'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import App from "./App"
import FourOFour from "./Views/FourOFour"
import Home from "./Views/Home"
import Login from "./Views/Login"
import Recuperar from "./Views/Recuperar"
import SuaPratica from "./Views/SuaPratica"
import Pratica from "./Views/Pratica"
import Explorar from "./Views/Explorar"
import Categorias from "./Views/Categorias"
import Categoria from "./Views/Categoria"
import Jornadas from "./Views/Jornadas"
import Jornada from "./Views/Jornada"
import Favoritos from "./Views/Favoritos"
import Sair from "./Views/Sair"
import Aulas from './Views/Aulas'


import './Assets/Fonts/gothamrounded_bold.otf'
import './Assets/Fonts/GothamRounded-Bold.ttf'
import './Assets/Fonts/gothamrounded_book.otf'
import './Assets/Fonts/GothamRounded-Book.ttf'
import './Assets/Fonts/gothamrounded_light.otf'
import './Assets/Fonts/GothamRounded-Light.ttf'
import './Assets/Fonts/gothamrounded_medium.otf'
import './Assets/Fonts/GothamRounded-Medium.ttf'
import './Assets/Fonts/lemonmilk_bold.otf'
import './Assets/Fonts/lemonmilk_bold_italic.otf'
import './Assets/Fonts/lemonmilk_light.otf'
import './Assets/Fonts/lemonmilk_light_italic.otf'
import './Assets/Fonts/lemonmilk_medium.otf'
import './Assets/Fonts/lemonmilk_medium_italic.otf'
import './Assets/Fonts/lemonmilk_regular.otf'
import './Assets/Fonts/lemonmilk_regular_italic.otf'

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(

    <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login/>} />
      <Route path="/recuperar/" element={<FourOFour />} />
      <Route path="/recuperar/:token" element={<Recuperar />} />
      <Route path="/" element={<App/>}>
        <Route path="home" element={<Home />} />
        <Route path="suapratica" element={<SuaPratica />} />
        <Route path="aulas" element={<Aulas />} />

        <Route path="pratica/:slug" element={<Pratica />} />
        
        <Route path="explorar" element={<Explorar />} />
        <Route path="categorias" element={<Categorias />} />
        <Route path="categoria/:slug" element={<Categoria />} />
        
        <Route path="jornadas" element={<Jornadas />} />
        <Route path="jornada/:slug" element={<Jornada/>} />
        
        <Route path="favoritos" element={<Favoritos />} />
        <Route path="sair" element={<Sair />} />
        
      </Route>
      <Route path="/sair" element={<Sair />} />
      <Route path="*" element={<FourOFour/>} />
    </Routes>
  </BrowserRouter>
  );