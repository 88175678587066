import { Outlet } from "react-router-dom";
import Api from './Models/Api'
import Storage from './Models/Storage'
import Functions from './Models/Functions'
import React, { useState, useEffect } from 'react';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import MenuDesktop from "./Components/MenuDesktop";
import MenuMobile from "./Components/MenuMobile";
import Loader from "./Components/Loader";

import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/Styles.css'
const pjson = require('./version.json');
const App = (props) =>  {

  const [isLoading, setIsLoading] = useState(true);
  const [navivationActive, setNavigationActive] = useState(false);

  const internal_routes = [
                            'home',
                            'aulas',
                            'suapratica',
                            'categorias',
                            'categoria',
                            'jornadas',
                            'jornada',
                            'praticas',
                            'pratica',
                            'recovery',
                            'sair'
                          ]
  const border_routes = [undefined,null,'','login']

  function goTo(to,time=2000){
    const href = window.location.href.split('/')
    if(
      to==='login' ||
      (
        href[3]!==to 
        && Functions.inArray(border_routes,href[3]) 
        && !Functions.inArray(internal_routes,href[3])
      )
      ){

        window.setTimeout(function(){
          window.location.href="/"+to
          window.setTimeout(function(){
            setIsLoading(false)
          },500)
        },time)
      } else {
        window.setTimeout(function(){
          setIsLoading(false)
        },500)
      }     
  }
  
  function currUrl(){
    const href = window.location.href.split('/')
    return href[3]
  }

  useEffect(() => {
    try{
      Api.get('/getConfigs').then((result)=>{
        return result.data
      }).then(async (apiRes)=>{
        if(apiRes.status===true){
          const token = await Storage.get('token')
          if(token!==null){
              await Api.get('/checkValid',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((userConfirmation)=>{
                if(typeof userConfirmation.data!='undefined' && userConfirmation.data.valid===true){
                  goTo('home',0)
                  setNavigationActive(true)
                  return true
                }
                goTo('login',0)
                return false
              }).catch(function (error) {
                goTo('login',0)
                return false
              })
          } else{
            goTo('login',0)
          } 
        } else {
          goTo('login')
          return false
        } 
      })
    } catch(e){
      goTo('login')
      return false
    }
  })
  

  if(isLoading) return (
    <Loader />
  )
  if(!isLoading && navivationActive)  return (  
  <div>
    { !isMobile &&
    <BrowserView><MenuDesktop active={currUrl()} /></BrowserView>
    }
    <div style={{overflow:"auto",overflowX:"hidden",marginTop:(isMobile?'0':'10vh'),minWidth:(isMobile?'100vw':'auto'),maxWidth:(isMobile?'100vw':'auto'),height:'auto',paddingBottom:(isMobile?'100px':'20px'),marginLeft:(isMobile?'0px':'15vw')}}>
      <Outlet />
    </div>
    { isMobile &&
    <MobileView><MenuMobile active={currUrl()} /></MobileView>
    }
  </div>
  )
  
 
}

export default App