import React, { useState }  from 'react';
import Api from '../Models/Api';
import isFav from '../Assets/fav.png'
import notFav from '../Assets/noFav.png'
import Storage from '../Models/Storage';
import { isMobile } from 'react-device-detect';

const pjson = require('../version.json');

const Favorite = (props) =>{
    
    const [isFavorite, setFavorite] = useState(props.isFavorite);

    function imageSize(){
        if(isMobile) return {width:'40px',height:'40px'}
        return {width:'50px',height:'50px'}
    }

    async function regFav(){
        const token = await Storage.get('token')
        setFavorite(!isFavorite)
        let bodyFormData = new FormData()
        bodyFormData.append('slug', props.slug)
        bodyFormData.append('type', props.type )
        Api.post('/'+(!isFavorite?'add':'remove')+'fav',bodyFormData,{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}})
    }
    if(props.showNotfav || isFavorite){
        if(props.showNotfav) return (
                                    <div>
                                        <button className='noButton' onClick={regFav}>
                                            <img style={imageSize()} src={(isFavorite?isFav:notFav)} alt="" />
                                        </button>
                                    </div>
                                )
        if(!props.showNotfav) return (
            <img style={{...props.imgStyles}} src={(isFavorite?isFav:notFav)} alt="" />
        )
        
    }
    
}

export default Favorite