import React from 'react'
import Api from '../Models/Api'
import Storage from '../Models/Storage'
import Loader from '../Components/Loader'
import LessonCard from '../Components/LessonCard'
import PracticalResume from '../Components/PracticalResume';
import Caches from '../Models/Caches'
import Banners from '../Components/Banners'
// import Firebase from '../Components/Firebase'

const pjson = require('../version.json');

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
                  isLoading:true,
                  lessonList:[],
                  user:{},
                  lang:{},
                  bannersList: []
    }
  }
  
  componentDidMount() {
    Caches().then(async (result)=>{
      this.setState({'lang':result.lang})
      const user = await Storage.get('user')
      Storage.set('currentJourney',false)
      this.setState({user:user})
      const token = await Storage.get('token')
      return token
    }).then(async (token)=>{
      this.getBanners(token)
      this.getRecentLessons(token)
      this.setState({'isLoading':false})
    }) 
    document.querySelectorAll('#zapBtn').forEach(link => {
      link.classList.remove('hidden');
    });
  }
  
  
  getRecentLessons(token){
    Api.get('/recentlessons',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then(async (result)=>{
      this.setState({'lessonList':result.data.lessons})
    }).catch(function (error) {
      console.log('Something goes wrong',error)
    })
  }

  
  getBanners(token){
    Api.get('/banners',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then(async (result)=>{
      this.setState({'bannersList':result.data})
    }).catch(function (error) {
      console.log('Something goes wrong',error)
    })
  }



  render(){
    if(this.state.isLoading) return ( <Loader />)
    if(!this.state.isLoading){
      return (  
        <div>
          <div className="row cardList">
          {/* <Firebase /> */}
          <Banners isHome={true} banners={this.state.bannersList}/>
          <PracticalResume user={this.state.user} isHome={true} lang={this.state.lang} />
          <h2 className="SessionTitle" style={{paddingTop:'0.8rem'}}>{this.state.lang.weekLessons}</h2>
          {this.state.lessonList.map((item) => <LessonCard key={item.id} lesson={item} lang={this.state.lang} isHome={true} />)}
          </div>
        </div>
      )
    }
  }    
  
  
  
}

export default Home;