import React  from 'react'
import Api from '../Models/Api'
import { useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { Button, TextareaAutosize } from '@material-ui/core'
import Loader from '../Components/Loader'
import Storage from '../Models/Storage'
import ReactPlayer from 'react-player'
import LessonLevel from '../Components/LessonLevel'
import Favorite from '../Components/Favorite'
import LessonCard from '../Components/LessonCard'
import Caches from '../Models/Caches'
import Evaluations from '../Components/Evaluations'
import thumbUp from '../Assets/icone_thumbsup.png'
import likedIcon from '../Assets/icone_liked.png'
import pipeIcon from '../Assets/icone_pipe.png'
import clockImage from '../Assets/clock.png'

const pjson = require('../version.json');

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />
}

class Pratica extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      lang:{},
      isLoading:true,
      currentJourney: '',
      session:{},
      lesson:{},
      lessonList:[],
      slug:this.props.params.slug,
      token:"",
      evalWindow: false,
      myEval: 0,
      myEvalText: '',
      underVote:[],
      iVote:[],
      totalEvals: 0,
      totalViews: 0
    }
    this.registerPlay = this.registerPlay.bind(this)
    this.doEval = this.doEval.bind(this)
    this.loadContent = this.loadContent.bind(this)
    this.p = {};
    this.isRewinded = false;
  }

  componentDidMount(){
    
      this.loadContent();
  }

  loadContent(){
    this.setState({'isLoading':true})
    Caches().then(async (result)=>{
      this.setState({'lang':result.lang})
      const session = await Storage.get('user')
      session.token = await Storage.get('token')
      this.setState({token:session.token,session:session})
      return session
    }).then(async (session)=>{
      this.setState({'slug':this.props.params.slug})
      return await Api.get('/lesson/'+this.props.params.slug,{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':session.token}}).then(async (result)=>{
        if(!isMobile && result.data.lesson.urlhd!=="" && result.data.lesson.urlhd!==null) result.data.lesson.url = result.data.lesson.urlhd
        await this.getLessonList(session.token,result.data.lesson.journey.slug)
        await this.setState({'lesson':result.data.lesson})
        const theEval = ( result.data.lesson.self_eval?.evaluation ) * 1
        await this.setState({
                            myEvalText:result.data.lesson.self_eval?.comment,
                            totalEvals: result.data.lesson.likes,
                            liked: result.data.lesson.liked,
                            totalViews: result.data.lesson.total_views
                          })
      const currentJourney = await Storage.get('currentJourney')
      if(currentJourney!==null && currentJourney!==false){
        this.setState({currentJourney:currentJourney})
      } else {
        this.setState({currentJourney:result.data.lesson.journey.name})
      }
        await this.setVote(theEval)
        await this.setState({'isLoading':false})
      })
    });
    document.querySelectorAll('#zapBtn').forEach(link => {
      link.classList.remove('hidden');
    });
  }

  async getLessonList(token,slug){
    let bodyFormData = new FormData()
        bodyFormData.append('journey_slug', slug)
        bodyFormData.append('page', 1)
        bodyFormData.append('results_per_page', 12)
    Api.post('/getlessons',bodyFormData,{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
      this.setState({lessonList:result.data.lessons})
    }).catch(function (error) {
      console.log('Something goes wrong',error)
    })
  }

  registerPlay(status){
    let bodyFormData = new FormData()
    bodyFormData.append('slug', this.props.params.slug)
    bodyFormData.append('total_duration', Math.floor( status.playedSeconds * 1000 ) )
    bodyFormData.append('time_past', 10000)
    Api.post('/registerconsumption/',bodyFormData,{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':this.state.token}})
  }

  async doEval(isLiking){
    this.setState({evalWindow:false})
    let bodyFormData = new FormData()
    bodyFormData.append('lesson_id', this.state.lesson.id)
    bodyFormData.append('liked', this.state.myEval )
    if(!!this.state.myEvalText){
      bodyFormData.append('comment', this.state.myEvalText)
    }
    await Api.post('/evaluate/',bodyFormData,{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':this.state.token}})
    if(!isLiking){
      let lesson = this.state.lesson
      lesson.evaluations.push({
              name:this.state.session.name,
              comment: this.state.myEvalText
      })
      lesson.self_eval = {
        name:this.state.session.name,
        comment: this.state.myEvalText,
        liked: this.state.liked
      }
      this.setState({lesson:lesson})
    }
  }

  async doLike(){
    await this.setState({myEval:1})
    if(!this.state.liked){
      this.setState({
                liked: true,
                totalEvals: this.state.totalEvals + 1
      })
    }
    await this.doEval(true)
  }

  thumbUp(){
    if(this.state.liked) return likedIcon
    return thumbUp
  }

  async setVote(nota){
    let underVote = []
    let iVote = []
    for(let u = 1;u<=nota;u++){
      iVote.push(u)
    }
    for(let u = iVote.length ;u<5;u++){
      underVote.push(u)
    }
    await this.setState({iVote:iVote,underVote:underVote,myEval:nota})
  }

  getCurrentEval(){
    if(typeof this.state.myEvalText!=="undefined" && this.state.myEvalText!=="undefined"  && this.state.myEvalText!==null ) return this.state.myEvalText
    return ''
  }

  styleBy(){
    if(isMobile) return {maxWidth:'100%',height:'auto'}
    return {maxWidth:'90%',width:'90%',marginLeft:"5%",marginTop:"25px",height:'auto'}
  }

  pipeImage(){
    return (<img src={pipeIcon} width="1px" height="20px" style={{width:"1px",height:"20px"}} alt="pipe" />)
  }

  makeLessonUrl(url){
    return url;
    // return url.replaceAll('/storage/','/hls/').replaceAll('.mp4','_Ott_Hls_1280x720_5000Kbps.m3u8');
  }

  rewindVideo(){
    if(!this.isRewinded){
      this.isRewinded=true;
      const timeToStart = Math.floor(this.state.lesson.played / 1000 );
      this.p.seekTo(timeToStart)
    }
  }

  render(){
    
    if(this.state.isLoading) return ( <Loader />)
    if(!this.state.isLoading) return (
      <div>
        <div className='playerHolder'>
      <ReactPlayer 
                  url={this.makeLessonUrl(this.state.lesson.url)} 
                  controls={true} 
                  playing={true}
                  playsinline={true}
                  config={{ file: { 
                    // forceHLS: true,
                    attributes: {
                      controlsList: 'nodownload'
                    }
                  }}}
                  width="100%"
                  height="auto"
                  // pip={true}
                  muted={false}
                  progressInterval={10000} 
                  onProgress={this.registerPlay}
                  style={this.styleBy()}
                  ref={(p) => {
                    this.p = p;
                  }}
                  onReady={()=>this.rewindVideo()}
                  />
        </div>
        <div style={{position:"relative",width:"90%",marginLeft:"5%", padding:"10px"}}>
          <h1 style={{fontSize:"1.5rem"}}>{this.state.lesson.name}</h1>
          <div className='row'>
            { isMobile &&
            <>
            {/* <div className='col-4 GothamRoundedLight rightBorderLine' style={{fontSize:"0.8rem",marginTop:"10px",textAlign:"center"}}>{this.state.currentJourney}</div> */}
            <div className='col-3 GothamRoundedLight rightBorderLine' style={{fontSize:"0.8rem",marginTop:"10px",textAlign:"center"}}><img src={clockImage} width="16px" height="16px" style={{width:"16px",height:"16px",marginRight:"5px"}} alt="duration" /> {Math.floor(this.state.lesson.duration/60000)}m</div>
            <div className='col-lg-4 col-5 GothamRoundedLight' style={{fontSize:"0.8rem",marginTop:"10px",textAlign:"center"}}><LessonLevel lang={this.state.lang} level={this.state.lesson.level} /></div>
            <div className='col-1 mobile-hidden'>
              <div style={{position:"absolute",right:"10px",top:"10px"}}>
                <Favorite 
                  isFavorite={this.state.lesson.isFavorite} 
                  slug={this.state.lesson.slug} 
                  showNotfav={true}
                  type="lesson" 
                  />
              </div>             
            </div>
            
            <div className='col-12' style={{position:"relative"}}>
              
              <div className='row'>
              <div className='divider-40'></div>
              <div className="col-lg-6 col-6 GothamRoundedMedium" style={{marginTop:"13px"}}>
                { this.state.totalViews>0 &&
                  <>{this.state.totalViews} {this.state.totalViews>1?this.state.lang.visualizations:this.state.lang.visualization}</>
                }
              </div>
              <div className="col-lg-4 col-4 text-right GothamRoundedMedium">
                <img src={this.thumbUp()} style={{width:(isMobile?"25px":"45px"),height:(isMobile?"25px":"45px"),margin:"10px"}} onClick={()=>this.doLike()} alt="totalLikes" />
                {this.state.totalEvals}
              </div>
              </div>
              
              { ( this.state.lesson.self_eval===null || (!!this.state.lesson.self_eval && ( this.state.lesson.self_eval.comment+''==='' || this.state.lesson.self_eval.comment===null )) ) &&
              <div className='row'>
                <div className='divider-40'></div>
                <div className="col-lg-2 col-2 text-center">
                <img src={this.state.session.picture} width="40px" height="40px" style={{width:"45px",height:"45px",marginRight:"10px"}} onClick={()=>this.doLike()} alt="duration" />
                </div>
                <div className="col-lg-10 col-10 text-center">
                  <TextareaAutosize
                    maxRows={2}
                    minRows={2}
                    onChange={(event)=>this.setState({myEvalText:event.target.value})}
                    aria-label={this.state.lang.comment}
                    placeholder={this.state.lang.comment}
                    defaultValue={this.getCurrentEval()}
                    style={{ width: '100%' }}
                    className="form-control"
                  />
                  <Button className='noButton pull-right' onClick={()=>this.doEval(false)}>{this.state.lang.do_comment}</Button>
                </div>
              </div>
              }
              <div className='divider-40'></div>
              <Evaluations lang={this.state.lang} evaluations={this.state.lesson.evaluations} />
            </div>
            </>
            }
            { !isMobile &&
            <>
            <div className='col-6 row GothamRoundedLight' style={{fontSize:"0.8rem",marginTop:"10px",textAlign:"center"}}>
              {/* <div style={{height:'20px',borderRight:"1px solid",borderColor:"#999"}} className='col-3 text-center'>{this.state.currentJourney}  </div> */}
              <div style={{height:'20px',borderRight:"1px solid",borderColor:"#999"}} className='col-2 text-center'><img src={clockImage} width="18px" height="18px" style={{width:"16px",height:"16px",marginRight:"5px"}} alt="duration" /> {Math.floor(this.state.lesson.duration/60000)}m </div>
              <div style={{height:'20px'}} className='col-3 text-center'><LessonLevel style={{width:'100%'}} lang={this.state.lang} level={this.state.lesson.level} /></div>
            </div>
            <div className='col-6 row GothamRoundedMedium'>
                <div className='col-5'></div>
                <div className='col-3 text-right' style={{alignSelf:"center"}}>
                { this.state.totalViews>0 &&
                  <>{this.state.totalViews} {this.state.totalViews>1?this.state.lang.visualizations:this.state.lang.visualization}</>
                }
                </div>
                <div className='col-2 text-center GothamRoundedLight' style={{alignSelf:"center",fontSize:"0.9rem"}}>
                  <img src={this.thumbUp()} style={{width:"35px",height:"35px",marginBottom:"5px"}} onClick={()=>this.doLike()} alt="duration" />
                  <br></br>
                  {this.state.totalEvals}
                </div>
                <div className='col-2' style={{alignSelf:"center"}}>
                  <Favorite 
                    isFavorite={this.state.lesson.isFavorite} 
                    slug={this.state.lesson.slug} 
                    showNotfav={true}
                    type="lesson" 
                    /> 
                  </div>          
            </div>
            
            <div className='col-12' style={{position:"relative"}}>
              
              { ( this.state.lesson.self_eval===null || (!!this.state.lesson.self_eval && ( this.state.lesson.self_eval.comment+''==='' || this.state.lesson.self_eval.comment===null )) ) &&
              <div className='row'>
                <div className='divider-40'></div>
                <div className="col-lg-1 col-1 text-right">
                <img src={this.state.session.picture} width="40px" height="40px" style={{width:"45px",height:"45px",marginRight:"10px"}} onClick={()=>this.doLike()} alt="duration" />
                </div>
                <div className="col-lg-5 col-11 text-center">
                  <TextareaAutosize
                    maxRows={2}
                    rows={2}
                    onChange={(event)=>this.setState({myEvalText:event.target.value})}
                    aria-label={this.state.lang.comment}
                    placeholder={this.state.lang.comment}
                    defaultValue={this.getCurrentEval()}
                    style={{ width:'100%',borderRadius:"0px" }}
                    className="form-control"
                  />
                  <Button className='commentButton pull-right' onClick={()=>this.doEval(false)}>{this.state.lang.do_comment}</Button>
                </div>
              </div>
              }
              <div className='divider-40'></div>
              <Evaluations lang={this.state.lang} evaluations={this.state.lesson.evaluations} />
            </div>
            </>
            }
          </div>
          <div className="row">
          {this.state.lessonList.map((item) => <LessonCard key={item.id} style={{maxWidth:"50%"}} internal={true} lesson={item} showNames={true} showJourney={false} lesser={true} cols={4} />)}
          </div>
        </div>
        
      </div>
    )
  }
}

export default withParams(Pratica);