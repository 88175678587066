import React  from 'react';
import Api from '../Models/Api';
import Loader from '../Components/Loader'
import Caches from '../Models/Caches';
import LessonCard from '../Components/LessonCard'
import BackArrow from '../Components/BackArrow';
import { useParams } from 'react-router-dom'

const pjson = require('../version.json');

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />
}

class Categoria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
                  isLoading:true,
                  lessonList:[],
                  lang:{}
    }
  }

  componentDidMount(){
    Caches().then(async (result)=>{
      this.setState({'lang':result.lang})
      const token = result.token
      return token
    }).then(async (token)=>{
      this.getData(token)
    });
    document.querySelectorAll('#zapBtn').forEach(link => {
      link.classList.remove('hidden');
    });
  }

 
  getData(token){
    let bodyFormData = new FormData()
        bodyFormData.append('category_slug', this.props.params.slug)
    Api.post('/getlessons',bodyFormData,{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
      this.setState({'lessonList':result.data.lessons})
      this.setState({'isLoading':false})
    }).catch(function (error) {
      console.log('Something goes wrong',error)
    })
  }

  render(){
  if(this.state.isLoading) return ( <Loader />)
  if(!this.state.isLoading){
    return (  
        <div className="row cardList">
        <BackArrow style={{float:"left"}} title="Aulas" />
        {this.state.lessonList.map((item) => <LessonCard key={item.id} lesson={item} showNames={true} showJourney={false} lesser={false} fromCategories={true} cols={4} />)}
        </div>
    )
  }
}
  
  
}

export default withParams(Categoria);