import React from 'react';
import { isMobile } from 'react-device-detect';
import EvaluationLine from './EvaluationLine';

function Evaluations(props){
    
    
    return (
        <div>
            <div>
                <div style={props.divStyle}>
                    <div className='row'>                
                        <div className="col-12" style={{fontSize:"1rem",fontFamily:'GothamRoundedBook',color:'#888888'}} >
                            {!isMobile &&
                            <div className="row" style={{margin:'10px 0px',borderBottom:'1px solid #888)',borderColor:'#888',paddingBottom:'8px'}}>
                               <div className="col-lg-1 col-1" style={{paddingLeft:'20px',paddingTop:'3px'}}>
                                   
                                </div>
                                <div className="col-lg-5 col-11" style={{paddingLeft:'20px',paddingTop:'3px'}}>
                                    
                                </div>
                            </div>
                            }
                            {props.evaluations?.map((item) => <EvaluationLine lang={props.lang} evaluation={item} key={item.created_at} />)}    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Evaluations;