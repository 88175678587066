import React from 'react';
import RankLine from './RankLine'

function Ranking(props){
    if(props.rankData.length>0) return (
    <div style={props.divStyle}>
        <h2 className="SessionTitle">{props.lang.ranking}</h2>
        <div className='row'>
        {props.rankData.map((item) => <RankLine data={item} key={item.position} />)}
        </div>
    </div>
  );
}

export default Ranking;