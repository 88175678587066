import React  from 'react'
import Api from '../Models/Api'
import Storage from '../Models/Storage'
import Loader from '../Components/Loader'
import LessonCard from '../Components/LessonCard'
import Caches from '../Models/Caches'
import CategoryCard from '../Components/CategoryCard'
import JourneysCard from '../Components/JourneysCard'
import Filters from '../Components/Filters'
import Constants from '../Assets/Constants.json'
import Functions from '../Models/Functions'
import { isMobile } from 'react-device-detect'

import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import Miniloader from '../Components/Miniloader'
import backArrow from '../Assets/back.png'

const pjson = require('../version.json');

class Explorar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
                  isLoading:true,
                  softLoading:true,
                  lessonList:[],
                  categoryList:[],
                  journeyList:[],
                  timeList:[],
                  token:'',
                  isFiltering: false,
                  paginate: true,
                  onlyLessons: false,
                  onlyCategories: false,
                  onlyJourneys: false,
                  searchLength: 8,
                  filters: {
                    categories:[],
                    tags:[],
                    duration:[],
                    levels:[]
                  },
                  lang:{}
    }
    this.listFiltering = this.listFiltering.bind(this);
  }

  componentDidMount(){
    Caches().then(async (result)=>{
      this.setState({'lang':result.lang})
      this.setState({"timeList":result.lang.timeList})
      const token = await Storage.get('token')
      Storage.set('currentJourney',false)
      await this.setState({token:token})
      return token
    }).then(async (token)=>{
      this.getData(token)
    });
    document.querySelectorAll('#zapBtn').forEach(link => {
      link.classList.remove('hidden');
    }); 
  }

  applyFilters(){
    this.listFiltering(this.state.filters ,true)
  }
  
  async listFiltering(data){
    await this.setState({isLoading:false})
    await this.setState({softLoading:true})
    await this.setState({isFiltering:true})
    await this.setState({filters:data})
    let durations = []
    if(data.duration.length>0){
      for(let r=0;r<this.state.timeList.length;r++){
        if(Functions.inArray(data.duration,this.state.timeList[r].id)){
          durations.push(this.state.timeList[r])
        }
      }
    }
    let bodyFormData = new FormData()
    bodyFormData.append('search_length', this.state.searchLength)
    bodyFormData.append('categories', JSON.stringify(data.categories))
    bodyFormData.append('search_tags', JSON.stringify(data.tags))
    bodyFormData.append('levels', JSON.stringify(data.levels))
    bodyFormData.append('search_term', this.getSearchText(data))
    bodyFormData.append('duration_filter', JSON.stringify(durations))
    
    Api.post('/search',bodyFormData,{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':this.state.token}}).then(async (result)=>{
      this.setState({'lessonList':result.data.lessons})
      this.setState({softLoading:true})
      this.setState({isFiltering:true})
      this.setState({softLoading:false})
      this.setState({isFiltering:false})
    }).catch(function (error) {
      console.log('Something goes wrong',error)
    })
  }

  getSearchText(data){
    if(typeof data.search_term!=='undefined'){
      return data.search_term
    }
    return ''
  }

  async checkCategory(id){
    let list = this.state.filters.categories
    if(this.categoryChecked(id)){
      list = list.filter(function(value, index, arr){ 
        return value !== id;
      })
    } else {
      list.push(id)
    }
    await this.setState({filters:{categories:list,tags:this.state.filters.tags,levels:this.state.filters.levels,duration:this.state.filters.duration,search_term:this.state.filters.search_term}})
    this.applyFilters()
  }

  categoryChecked(id){
    return Functions.inArray(this.state.filters.categories,id)
  }
  
  async checkLevel(id){
    let list = this.state.filters.levels
    if(this.levelChecked(id)){
      list = list.filter(function(value, index, arr){ 
        return value !== id;
      })
    } else {
      list.push(id)
    }
    await this.setState({filters:{categories:this.state.filters.categories,tags:this.state.filters.tags,levels:list,duration:this.state.filters.duration,search_term:this.state.filters.search_term}})
    this.applyFilters()
  }

  levelChecked(id){
    return Functions.inArray(this.state.filters.levels,id)
  }

  async checkTag(id){
    let list = this.state.filters.tags
    if(this.tagChecked(id)){
      list = list.filter(function(value, index, arr){ 
        return value !== id;
      })
    } else {
      list.push(id)
    }
    await this.setState({filters:{categories:this.state.filters.categories,levels:this.state.filters.levels,tags:list,duration:this.state.filters.duration,search_term:this.state.filters.search_term}})
    this.applyFilters()
  }
  tagChecked(id){
    return Functions.inArray(this.state.filters.tags,id)
  }
  
  async checkDuration(id){
    let list = this.state.filters.duration
    if(this.durationChecked(id)){
      list = list.filter(function(value, index, arr){ 
        return value !== id;
      })
    } else {
      list.push(id)
    }
    await this.setState({filters:{categories:this.state.filters.categories,levels:this.state.filters.levels,tags:this.state.filters.tags,duration:list,search_term:this.state.filters.search_term}})
    this.applyFilters()
  }
  durationChecked(id){
    return Functions.inArray(this.state.filters.duration,id)
  }
  
  getData(token){
    this.setState({isFiltering:true})
    Api.get('/categories',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
      this.setState({'categoryList':result.data})
    }).then(()=>{
      Api.get('/tags',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
        this.setState({'tagList':result.data})
      }).then(()=>{
        this.setState({'isLoading':false})
        Api.get('/search',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
          this.setState({'lessonList':result.data.lessons})
          this.setState({softLoading:false})
          this.setState({isFiltering:false})
        }).catch(function (error) {
          console.log('Something goes wrong',error)
        })
      })
    })
    
    
  }

  async seeMore(type){
    await this.setState({searchLength:999,paginate:false})
    if(type==='categories'){
      this.setState({onlyCategories:true})
    }
    if(type==='lessons'){
      this.setState({onlyLessons:true})
    }
    if(type==='journeys'){
      this.setState({onlyJourneys:true})
    }
    window.scrollTo(0,0)
    this.applyFilters()
  }

  unseeMore(){
    console.log('ouch')
    this.setState({
                  paginate:true,
                  onlyCategories:false,
                  onlyLessons:false,
                  onlyJourneys:false,
                  searchLength:8
    })
    window.scrollTo(0,0)
    this.applyFilters()
  }

  render(){
  if(this.state.isLoading) return ( <Loader />)
  if(!this.state.isLoading){
    return (  
      <div>
        <Filters filters={this.state.filters} listFiltering={this.listFiltering} />
        <>
        { isMobile &&
          <>
          { this.state.lessonList.length > 0 &&
          <div className="row cardList" style={{position:"relative"}}>
          <h2 className="SessionTitle col-6" style={{color:"black"}}>{this.state.lang.lessons}</h2>
          <div className="col-6" style={{textAlign:"right"}}>
            <a className='noButton SeeMoreText'  style={{color:Constants.pallete.darkGreen}} href="/aulas">{this.state.lang.see_more}</a>
          </div>
          {this.state.lessonList.map((item) => <LessonCard lang={this.state.lang} key={item.id} lesson={item} showNames={true} showJourney={false} lesser={true} />)}
          </div>
          }
          
          { this.state.categoryList.length > 0 &&
          <div className="row cardList">
          <h2 className="SessionTitle col-6" style={{color:"black"}}>{this.state.lang.categories}</h2>
          <div className="col-6" style={{textAlign:"right"}}>
            <a className='noButton SeeMoreText'  style={{color:Constants.pallete.darkGreen}} href="/categorias">{this.state.lang.see_more}</a>
          </div>
          {this.state.categoryList.map((item) => <CategoryCard lang={this.state.lang} key={item.id} category={item} showNames={false} lesser={true} />)}
          </div>
          }

          { this.state.journeyList.length > 0 &&
          <div className="row cardList">
          <h2 className="SessionTitle col-6" style={{color:"black"}}>{this.state.lang.journeys}</h2>
          <div className="col-6" style={{textAlign:"right"}}>
            <a className='noButton SeeMoreText'  style={{color:Constants.pallete.darkGreen}} href="/jornadas">{this.state.lang.see_more}</a>
          </div>
          {this.state.journeyList.map((item) => <JourneysCard lang={this.state.lang} hideme={false} key={item.id} journey={item} showNames={true} lesser={true} />)}
          </div>
          }
          </>
        }
        { !isMobile &&
          <div className='row'>            
            <div className='col-2'>
              <h4 className="SeeMoreText">Por Duração</h4>
              <table style={{marginTop:"10px",width:"auto"}}><tbody>
              {this.state.timeList?.map((item) => 
                                                  <tr className="">
                                                    <td><FormControlLabel label={<Typography style={{fontFamily:"GothamRoundedLight",fontSize:"14px"}} >{item.name}</Typography>} key={"duration_"+item.id+"_holder"} control={<Checkbox key={"duration_"+item.id}  checked={this.durationChecked(item.id)} />} onChange={()=>this.checkDuration(item.id)} /></td>
                                                  </tr>
                                                )}
              </tbody></table>
              <hr></hr>
              <h4 className="SeeMoreText">Por Categoria</h4>
              <table style={{marginTop:"10px",width:"auto"}}><tbody>
              {this.state.categoryList?.map((item) => 
                                                  <tr className="">
                                                  <td><FormControlLabel label={<Typography style={{fontFamily:"GothamRoundedLight",fontSize:"14px"}} >{item.name}</Typography>}  key={"tag_"+item.id+"_holder"} control={<Checkbox key={"tag_"+item.id}  checked={this.categoryChecked(item.id)} />} onChange={()=>this.checkCategory(item.id)} /></td>
                                                </tr>)}
              </tbody></table>
              <hr></hr>
              <h4 className="SeeMoreText">Por Nível</h4>
              <table style={{marginTop:"10px",width:"auto"}}><tbody>
              {this.state.lang.levels_filter?.map((item) => 
                                                  <tr className="">
                                                  <td><FormControlLabel label={<Typography style={{fontFamily:"GothamRoundedLight",fontSize:"14px"}} >{item.title}</Typography>}  key={"tag_"+item.id+"_holder"} control={<Checkbox key={"tag_"+item.id}  checked={this.levelChecked(item.id)} />} onChange={()=>this.checkLevel(item.id)} /></td>
                                                </tr>)}
              </tbody></table>
              <hr></hr>
              <h4 className="SeeMoreText">Por Tag</h4>
              <div style={{maxHeight:"60vh",overflow:"auto",overflowX:"hidden"}}>
              <table style={{marginTop:"10px",width:"auto"}}><tbody>
              {this.state.tagList?.map((item) => 
                                                  <tr className="">
                                                  <td><FormControlLabel label={<Typography style={{fontFamily:"GothamRoundedLight",fontSize:"14px"}} >{item.title}</Typography>} key={"tag_"+item.id+"_holder"} control={<Checkbox key={"tag_"+item.id}  checked={this.tagChecked(item.id)} />} onChange={()=>this.checkTag(item.id)} /></td>
                                                </tr>)}
              </tbody></table>
              </div>
            </div>
            <div className='col-10'>
            { this.state.isFiltering &&
              <Miniloader lang={this.state.lang} />
            }
             { this.state.paginate &&
              <>
            { !this.state.isFiltering &&
              <>
            { this.state.lessonList.length > 0 &&
            <div className="row cardList" style={{position:"relative"}}>
            <h2 className="SessionTitle col-6" style={{color:"black"}}>{this.state.lang.lessons}</h2>
            <div className="col-6" style={{textAlign:"right"}}>
              <a className='noButton SeeMoreText' href={() => false} style={{color:Constants.pallete.darkGreen,paddingRight:"8px"}} onClick={()=>this.seeMore('lessons')}>{this.state.lang.see_more}</a>
            </div>
            {this.state.lessonList.map((item) => <LessonCard filteredDesktop={true} lang={this.state.lang} key={item.id} lesson={item} showNames={true} showJourney={false} lesser={true} />)}
            </div>
            }
            
            { this.state.categoryList.length > 0 &&
            <div className="row cardList">
            <h2 className="SessionTitle col-6" style={{color:"black"}}>{this.state.lang.categories}</h2>
            <div className="col-6" style={{textAlign:"right"}}>
              <a className='noButton SeeMoreText' href={() => false} style={{color:Constants.pallete.darkGreen}} onClick={()=>this.seeMore('categories')}>{this.state.lang.see_more}</a>
            </div>
            {this.state.categoryList.map((item) => <CategoryCard filteredDesktop={true}  lang={this.state.lang} key={item.id} category={item} showNames={false} lesser={true} />)}
            </div>
            }

            { this.state.journeyList.length > 0 &&
            <div className="row cardList">
            <h2 className="SessionTitle col-6" style={{color:"black"}}>{this.state.lang.journeys}</h2>
            <div className="col-6" style={{textAlign:"right"}}>
              <a className='noButton SeeMoreText' href={() => false} style={{color:Constants.pallete.darkGreen}} onClick={()=>this.seeMore('journeys')}>{this.state.lang.see_more}</a>
            </div>
            {this.state.journeyList.map((item) => <JourneysCard filteredDesktop={true}  lang={this.state.lang} hideme={false} key={item.id} journey={item} showNames={true} lesser={true} />)}
            </div>
            }
              </>
            }
            </>
            }
            { !this.state.paginate &&
              <>
            { !this.state.isFiltering &&
              <>
              <div className='row' style={{paddingBottom:"10px"}}>
                <button className='col-2 noButton' onClick={()=>this.unseeMore()}>
                <img src={backArrow}  style={{width:'1.5rem'}} alt="voltar"/> 
                </button>
                <div className='col-10'>
                <h2 className="SessionTitle" style={{paddingTop:'0.8rem'}}>{this.state.lang.textBack}</h2>
                </div>
            </div>
            { this.state.onlyLessons && this.state.lessonList.length > 0 &&
            <div className="row cardList" style={{position:"relative"}}>
            <h2 className="SessionTitle col-6" style={{color:"black"}}>{this.state.lang.lessons}</h2>
            <div className="col-6" style={{textAlign:"right"}}>
            </div>
            {this.state.lessonList.map((item) => <LessonCard filteredDesktop={true} lang={this.state.lang} key={item.id} lesson={item} showNames={true} showJourney={false} lesser={true} />)}
            </div>
            }
            
            { this.state.onlyCategories && this.state.categoryList.length > 0 &&
            <div className="row cardList">
            <h2 className="SessionTitle col-6" style={{color:"black"}}>{this.state.lang.categories}</h2>
            <div className="col-6" style={{textAlign:"right"}}>
            </div>
            {this.state.categoryList.map((item) => <CategoryCard filteredDesktop={true}  lang={this.state.lang} key={item.id} category={item} showNames={false} lesser={true} />)}
            </div>
            }

            { this.state.onlyJourneys && this.state.journeyList.length > 0 &&
            <div className="row cardList">
            <h2 className="SessionTitle col-6" style={{color:"black"}}>{this.state.lang.journeys}</h2>
            <div className="col-6" style={{textAlign:"right"}}>
            </div>
            {this.state.journeyList.map((item) => <JourneysCard filteredDesktop={true}  lang={this.state.lang} hideme={false} key={item.id} journey={item} showNames={true} lesser={true} />)}
            </div>
            }
              </>
            }
            </>
            }
            </div>
          </div>
        }
        </>
      </div>
    )
  }
  }
  
}

export default Explorar;