import React from 'react';
import { isMobile } from 'react-device-detect';

function CategoryCard(props){
    let className = "col-lg-3 col-sm-12 col-xs-12 lessonCard"
    let innerClass = "lessonInnerCard"
    let imageStyle ={backgroundColor:"#efefef",backgroundImage:"url("+props.category.thumbnail+")",backgroundPosition:"center",backgroundSize:"cover",borderRadius: '5px', width:'10rem',height:'10rem'}
    if(props.lesser===true){
        className = "col-6"
        innerClass = "lessonInnerCard noBorder"
    }
    if(!isMobile){
        className = "col-3"
        imageStyle.width = '18vw'
        imageStyle.height = '40vh'
    }
    if(props.filteredDesktop){
        imageStyle.height = '20vh'
        imageStyle.width = '15vw'            
    }
    return (
            <div className={className}>
                <div className={innerClass}>
                    <a href={'/categoria/'+props.category.slug}>
                    <div style={imageStyle}></div>
                    </a>
                </div>    
            </div>    
    );

}


export default CategoryCard;