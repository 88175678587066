import React from 'react';
import { Chart as ChartJS, ArcElement } from 'chart.js';

import graphBG from '../Assets/graphbg.png'
import graphBGHome from '../Assets/graphbgHome.png'
import { Doughnut } from 'react-chartjs-2';

function DonutGraph(props){
  ChartJS.register(ArcElement);
  window.setTimeout(function(){
    if(
      !!ChartJS.defaults &&
      !!ChartJS.defaults.plugins &&
      !!ChartJS.defaults.plugins.tooltip &&
      !!ChartJS.defaults.plugins.tooltip.enabled
      ){
      ChartJS.defaults.plugins.tooltip.enabled = false
    }
  },1000)
  const innerStyle = {
                position:"absolute",
                top:(props.isHome?"90px":"190px"),
                right:"40px",
                width: "20vh",
                height: "16vh"
                }
  return (
    <div style={(!props.isMobile?props.graphStyle:innerStyle)}>
     <Doughnut id={props.canvasId} data={props.graphData} style={{backgroundImage:"url("+(props.isHome?graphBGHome:graphBG)+")",backgroundSize:"cover",backgroundPosition:"top"}} />
    </div>
  );
}

export default DonutGraph;