import { Link } from "react-router-dom";
import React from 'react';
import cfg from '../Assets/Constants.json'
import '../Assets/Styles.css'

import homeImage from '../Assets/inicio.png'
import homeImageOn from '../Assets/inicioOn.png'

import exploreImage from '../Assets/explorar.png'
import exploreImageOn from '../Assets/explorarOn.png'

import favImage from '../Assets/favoritos.png'
import favImageOn from '../Assets/favoritosOn.png'

import journeysImage from '../Assets/jornadas.png'
import journeysImageOn from '../Assets/jornadasOn.png'

import praticeImage from '../Assets/suapratica.png'
import praticeImageOn from '../Assets/suapraticaOn.png'

import Caches from '../Models/Caches';

const subTextStyles = {color:cfg.pallete.darkGreen,padding:0,fontSize:'0.5rem',fontWeight:'700',fontFamily:"GothamRoundedMedium",textAlign:"center"}

const menuIconStyle = {width:"16vw",height:"auto",margin:'auto auto'}
class MenuMobile extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
                    activeMenu:props.active,
                    lang:{}
      }
    }
  
    componentDidMount(){
      Caches().then(async (result)=>{
        this.setState({'lang':result.lang})
      })
    }

    getHomeImage(){
        if(this.state.activeMenu==='home'){
            return (
                <>
                <img src={homeImageOn} alt="home" style={menuIconStyle}/>
                <p style={subTextStyles}>{this.state.lang?.menu?.home}</p>
                </>
            )
        }  
        return <img src={homeImage} alt="home" style={menuIconStyle}/>
    }

    getSearchImage(){
        if(this.state.activeMenu==='explorar'){
            return (
                <>
                <img src={exploreImageOn} alt="explorar" style={menuIconStyle}/>
                <p style={subTextStyles}>{this.state.lang?.menu?.explore}</p>
                </>
            )
        } 
        return <img src={exploreImage} alt="explorar" style={menuIconStyle}/>
    }

    getJourneyImage(){
        if(this.state.activeMenu==='jornadas' || this.state.activeMenu==='jornada'){
            return (
                <>
                <img src={journeysImageOn} alt="jornadas" style={menuIconStyle}/>
                <p style={subTextStyles}>{this.state.lang?.menu?.journey}</p>
                </>
            )
        } 
        return <img src={journeysImage} alt="jornadas" style={menuIconStyle}/>
        
    }

    getFavoritesImage(){
        if(this.state.activeMenu==='favoritos'){
            return (
                <>
                <img src={favImageOn} alt="favoritos" style={menuIconStyle}/>
                <p style={subTextStyles}>{this.state.lang?.menu?.favorite}</p>
                </>
            )
        } 
        return <img src={favImage} alt="jornadas" style={menuIconStyle}/>
    }

    getPraticeImage(){
        if(this.state.activeMenu==='praticas' || this.state.activeMenu==='pratica' || this.state.activeMenu==='suapratica'){
            return (
                <>
                <img src={praticeImageOn} alt="praticas" style={menuIconStyle}/>
                <p style={subTextStyles}>{this.state.lang?.menu?.pratice}</p>
                </>
            )
        } 
        return <img src={praticeImage} alt="jornadas" style={menuIconStyle}/>
    }


    render(){
    return (<ul
      style={{
            padding: '0px',
            margin: '0px',
            minHeight:"100px",
            width:"100vw",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignContent: "center",
            listStyle: "none",
            backgroundColor:"white",
            position:"fixed",
            bottom:"0"
        }}
    >
        <li style={{padding:'4px'}} onClick={()=> this.setState({"activeMenu":'home'})}>         
            <Link to="/home" style={{textDecoration:"none"}} className="mobileMenuItem">
                <center>{this.getHomeImage()}</center>
            </Link>
        </li>
        <li style={{padding:'4px'}} onClick={()=> this.setState({"activeMenu":'explorar'})}>   
            <Link to="/explorar" style={{textDecoration:"none"}} className="mobileMenuItem">
                <center>{this.getSearchImage()}</center>
            </Link>
        </li>
        <li style={{padding:'4px'}} onClick={()=> this.setState({"activeMenu":'jornadas'})}>     
            <Link to="/jornadas" style={{textDecoration:"none"}} className="mobileMenuItem">
                <center>{this.getJourneyImage()}</center>
            </Link>
        </li>
        <li style={{padding:'4px'}} onClick={()=> this.setState({"activeMenu":'favoritos'})}>      
            <Link to="/favoritos" style={{textDecoration:"none"}} className="mobileMenuItem">
                <center>{this.getFavoritesImage()}</center>
            </Link>
        </li>
        <li style={{padding:'4px'}} onClick={()=> this.setState({"activeMenu":'suapratica'})}>     
            <Link to="/suapratica" style={{textDecoration:"none"}} className="mobileMenuItem">
                <center>{this.getPraticeImage()}</center>
            </Link>
        </li>
    </ul>)
}
}

export default MenuMobile