import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Favorite from './Favorite';
import bar from '../Assets/bar.png'
import antibar from '../Assets/antibar.png'

function LessonCard(props){

    const [elementHeight, setElementHeight] = useState(0);
    const [elementWidth, setElementWidth] = useState(0);
    const [elementMarginLeft, setElementMarginLeft] = useState(0);
    const [elementMarginRight, setElementMarginRight] = useState(0);
    const [rightMarginTimeCounter, setRightMarginTimeCounter] = useState(50);
    const [bottomMarginRight, setBottomMarginRight] = useState(95);
    const [lessonName, setLessonName] = useState(props.lesson?.name);

    let subtitleStyle = {}
    let titleStyle = {}
    let className = "col-lg-4 col-sm-12 col-xs-12 lessonCard cardCalculator"
    let innerClass = "lessonInnerCard noBorder"
    let cardClass = {position:"relative"}
    let hearth = {position:'absolute',top:'15px',left:'25px',width:'25px',height:'25px'}
    let imageStyle ={backgroundImage:"url("+props.lesson.thumbnail+")",backgroundPosition:"center",backgroundSize:"cover",borderRadius: '5px', width:'10rem',height:'10rem'}
    if(props.lesser===true && props.cols!==4){
        className = "col-6 cardCalculator"
    } 
    if(!isMobile){
        imageStyle.width = '25vw'
        imageStyle.height = '30vh'
        imageStyle.maxHeight = '30vh'
        subtitleStyle = {fontSize:"0.8rem"}
        titleStyle = {fontSize:"1.1rem"}
        hearth = {position:'absolute',top:'35px',left:'45px',width:'50px',height:'50px'}
        if(props.internal===true){
            imageStyle.width = '100%'
            imageStyle.height = '30vh'
            imageStyle.maxHeight = '30vh'
        }
        if(props.filteredDesktop){
            imageStyle.width = '100%'
            imageStyle.minHeight = 'auto'
            imageStyle.maxHeight = '30vh'
        }
    }

    function calcMadeWidth(){
        return ((props.lesson.played/props.lesson.duration)*elementWidth)
    }
    function calcUnmadeWidth(){
        return elementWidth - ((props.lesson.played/props.lesson.duration)*elementWidth)
    }
    
    window.setTimeout(function(){
        redoMargins()
    },1000)
    window.setTimeout(function(){
        redoMargins()
    },3000)

    function redoMargins(){
        setLessonName(lessonName)
        setElementHeight(document.getElementsByClassName('execLessonCard')[0]?.clientHeight - 15)
        setElementWidth(document.getElementsByClassName('execLessonCard')[0]?.clientWidth)
        let leftMarg = getStyle(document.getElementsByClassName('cardCalculator')[0],'padding-left').replace('px','') * 1 + getStyle(document.getElementsByClassName('lessonInnerCard')[0],'padding-left').replace('px','') * 1
        let rightMarg = getStyle(document.getElementsByClassName('cardCalculator')[0],'padding-right').replace('px','') * 1 + getStyle(document.getElementsByClassName('lessonInnerCard')[0],'padding-right').replace('px','') * 1
        setElementMarginLeft(leftMarg)
        setElementMarginRight(rightMarg)
        setRightMarginTimeCounter((props.isHome?30:(props.lesser?(props.cols!==4?30:40):30)))
        setBottomMarginRight(props.fromCategories?100:(calcMadeWidth() > 0?(props.isHome?95:80):(props.isHome?85:(props.lesser?85:70))))
    }

    function calcVideoTimer(){
        if(props.lesson.duration===1) return "AO VIVO"
        let time = Math.floor(props.lesson.duration / 1000 )
        let minutes = Math.floor(time / 60);
        let seconds = time - (minutes*60)
        if(seconds<10) seconds = '0'+seconds
        return minutes+':'+seconds
    }

    function navigateToLesson(){
        if(props.lesson.url_youtube!=='' && props.lesson.url_youtube!==null){
            window.open(props.lesson.url_youtube);
        } else {
            window.location.href = '/pratica/'+props.lesson.slug;
        }
    }

    var getStyle = function(e, styleName) {
        var styleValue = "";
        try {
            if (document.defaultView && document.defaultView.getComputedStyle) {
                styleValue = document.defaultView.getComputedStyle(e, "").getPropertyValue(styleName);
              } else if (e.currentStyle) {
                styleName = styleName.replace(/-(\w)/g, function(strMatch, p1) {
                  return p1.toUpperCase();
                });
                styleValue = e.currentStyle[styleName];
            }
            return styleValue;
        } catch(e){
            return '30px'
        }
      }
      
    return (
            <div className={className} style={cardClass}>
                <div className={innerClass}>
                    { calcMadeWidth() > 0 &&
                    <>
                    <img src={antibar} alt="unSeen" style={{borderBottomRightRadius:'5px',height:"20px",width:calcUnmadeWidth()+"px",position:"absolute",top:elementHeight+'px',left:calcMadeWidth()+elementMarginLeft,right:elementMarginRight}} />
                    <img src={bar} alt="seen" style={{borderBottomRightRadius:(calcUnmadeWidth()>1?'0':'5px'),borderBottomLeftRadius:'5px',height:"20px",width:calcMadeWidth()+"px",position:"absolute",left:elementMarginLeft,top:elementHeight+'px'}} />
                    </>
                    }
                    <div onClick={()=>navigateToLesson()} style={{cursor:'pointer'}}>
                    <div className="video_time" style={{right:rightMarginTimeCounter,bottom:bottomMarginRight}}>{calcVideoTimer()}</div>
                    { props.lesser!==true &&
                    <img src={props.lesson.thumbnail} alt="" className='execLessonCard' style={{borderRadius: '5px', width:'100%',height:'30vh'}} />
                    }
                    { props.lesser===true &&
                    <div style={imageStyle} className='execLessonCard'></div>
                    }
                   
                    <small style={subtitleStyle}>{(props.showJourney?props.lesson.journey.name:props.lesson.categories[0].name)}</small>
                    <h3 style={titleStyle}>{lessonName}</h3>
                    <Favorite 
                            imgStyles={hearth}
                            isFavorite={props.lesson.isFavorite} 
                            slug={props.lesson.slug} 
                            showNotfav={false}
                            type="lesson" 
                            />                    
                    </div>
                </div>    
            </div>    
    );

}


export default LessonCard;