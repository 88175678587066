import React from 'react';
import backArrow from '../Assets/back.png'

const BackArrow = (props) =>{
    return (<div className='row' style={{paddingBottom:"10px"}}>
        <button className='col-2 noButton' onClick={()=>window.history.back()}>
        <img src={backArrow}  style={{width:'1.5rem'}} alt="voltar"/> 
        </button>
        <div className='col-10'>
        <h2 className="SessionTitle" style={{paddingTop:'0.8rem'}}>{props.title}</h2>
        </div>
    </div>
    
    )
    
}

export default BackArrow