import React from 'react'
import Api from '../Models/Api'
import Loader from '../Components/Loader'
import LessonCard from '../Components/LessonCard'
import Caches from '../Models/Caches'

const pjson = require('../version.json');

class Favoritos extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
                  isLoading:true,
                  lessonList:[],
                  lang:{}
    }
  }

  componentDidMount(){
    Caches().then(async (result)=>{
      const token = result.token
      this.setState({'lang':result.lang})
      return token
    }).then(async (token)=>{
      this.getData(token)
    });
    document.querySelectorAll('#zapBtn').forEach(link => {
      link.classList.remove('hidden');
    }); 
  }

  
  getData(token){
    Api.get('/favorites',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
      this.setState({'lessonList':result.data.lessons})
      this.setState({'isLoading':false})
    }).catch(function (error) {
      console.log('Something goes wrong',error)
    })
  }
   
  render(){ 
  if(this.state.isLoading) return ( <Loader />)
  if(!this.state.isLoading){
    return (  
        <div className="row cardList">
        <h2 className='SessionTitle' style={{padding:"20px"}}>{this.state.lang.menu.favoriteCc}</h2>
        {this.state.lessonList.map((item) => <LessonCard key={item.id} lesson={item} showNames={true} showJourney={false} lesser={true} fromCategories={true} cols={4} />)}
        </div>
    )
  }
  
  }
}

export default Favoritos;