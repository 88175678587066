import React from 'react'
import Api from '../Models/Api'
import { useParams } from 'react-router-dom'
import Loader from '../Components/Loader'
import LessonCard from '../Components/LessonCard'
import BackArrow from '../Components/BackArrow'
import Caches from '../Models/Caches'

const pjson = require('../version.json');

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />
}
class Jornada extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
                  isLoading:true,
                  lessonList:[],
                  lang:{}
    }
  }

  componentDidMount(){
    Caches().then(async (result)=>{
      const token = result.token
      this.setState({'lang':result.lang})
      return token
    }).then(async (token)=>{
      this.getData(token)
    });
    document.querySelectorAll('#zapBtn').forEach(link => {
      link.classList.remove('hidden');
    }); 
  }
  
  getData(token){
    let bodyFormData = new FormData()
        bodyFormData.append('journey_slug', this.props.params.slug)
    Api.post('/getlessons',bodyFormData,{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
      this.setState({'lessonList':result.data.lessons})
      this.setState({'isLoading':false})
      Storage.set('currentJourney',this.state.lessonList[0].journey.name)
    }).catch(function (error) {
      console.log('Something goes wrong',error)
    })
  }
  render(){
    console.log('lessons',this.state.lessonList)
  if(this.state.isLoading) return ( <Loader />)
  if(!this.state.isLoading){
    return (  
        <div className="row cardList">
        <BackArrow style={{float:"left"}} title={this.state.lessonList[0].journey.name} />
        {this.state.lessonList.map((item) => <LessonCard key={item.id} lesson={item} showNames={true} showJourney={false} lesser={true} cols={4} />)}
        </div>
    )
  }
  }
  
}

export default withParams(Jornada);