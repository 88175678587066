
import React from 'react';
function JourneyHeader(props){
    
    return(
        <div>
            <div style={{padding:'0',margin:'0',width:'100%',backgroundColor:"#efefef",height:'35vh',backgroundImage:"url("+props.journey.thumbnail+")",backgroundSize:"contain",backgroundRepeat:"no-repeat",backgroundPosition:"center"}}></div>
            <div style={{padding:'15px'}}>
                <h2>{props.journey.name}</h2>
                <small className="GothamRoundedBook" style={{color:"#888"}}>{props.journey.description}</small>
                <div style={{marginTop:"20px",marginBottom:"20px"}}>
                <a href={"/jornada/"+props.journey.slug} className='button' style={{padding:"10px 50px"}}>{props.lang.lessons}</a> 
            </div>
            </div>
        </div>
    )

}

export default JourneyHeader;