import React  from 'react'
import Api from '../Models/Api'
import { useParams } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import { Button } from '@material-ui/core'
import Loader from '../Components/Loader'
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Caches from '../Models/Caches'
import '../Assets/Styles.css'
import { isMobile } from 'react-device-detect';

import logo from '../Assets/logo.png'

const theme = {
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: "4vw",
    marginRight: "4vw",
    marginTop: "15vh",
    maxWidth: "100vw"
  },
  mainMobile: {
    width: 400,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto', 
    maxWidth: "100vw",
    marginTop: "10vh",
  },
  paper: {
    marginTop: "10vh",
    margin: "auto auto",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: "none",
    boxShadow: "none",
    width: "40vw"
  },
  paperMobile: {
    margin: "auto auto",
    marginTop: "4vh",
    display: 'flex',
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: "4vw",
    flexDirection: 'column',
    alignItems: 'center',
    border: "none",
    boxShadow: "none"
  },
  form: {
    width: '20vw', 
    marginTop: "2vh",
  },
  formMobile: {
    marginTop: "2vh",
    width: '80vw'
  }
};

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />
}



class Recuperar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lang:{},
      isLoading:true,
      token:this.props.params.token,
      password:"",
      passwordCheck:"",
      error:"",
      info:"",
      hideAll: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangePasswordText = this.handleChangePasswordText.bind(this)
    this.handleChangePasswordCheckText = this.handleChangePasswordCheckText.bind(this)
    
  }
  
  componentDidMount(){
    this.loadContent()
  }

  async loadContent(){
    this.setState({'isLoading':true})
    Caches().then(async (result)=>{
      this.setState({'lang':result.lang,isLoading:false})
    })
  }

  async handleSubmit(e){
    e.preventDefault()
    if(this.state.passwordCheck!=='' && this.state.password !== this.state.passwordCheck){
      this.setState({error:this.state.lang.passwordsNotCheck})
    } else if(this.state.passwordCheck===''){
      this.setState({error:this.state.lang.passwordsCantBeBlank})
    } else if(this.state.passwordCheck.length < 6){
      this.setState({error:this.state.lang.minimalSixDigits})
    } else {
      this.setState({error:""})
      let bodyFormData = new FormData()
          bodyFormData.append('password', this.state.password)
      Api.post('/password_recovery/'+this.state.token,bodyFormData).then((result)=>{
        result = result.data
        if(result.error === "INVALID_TOKEN"){
          this.setState({hideAll:true,info:"",error:this.state.lang.invalidPassToken,password:"",passwordCheck:""})
        } else if(typeof result.error !== "undefined"){
          this.setState({hideAll:true,info:"",error:this.state.lang.somethingGoesWrong,password:"",passwordCheck:""})
        } else {
          this.setState({hideAll:true,error:"",info:this.state.lang.passwordChanged,password:"",passwordCheck:""})
        } 
        window.setTimeout(function(){
          window.location.href="/"
        },4000)
      }).catch(function (error) {
        this.setState({info:"",error:this.state.lang.somethingGoesWrong,password:"",passwordCheck:"",checkDisabled:true})
        console.log('Something goes wrong',error)
      })
    }
  }

  handleChangePasswordText(event){
    this.setState({password:event.target.value})
  }

  handleChangePasswordCheckText(event){
    this.setState({passwordCheck:event.target.value})
  }


  render(){
    
    if(this.state.isLoading) return ( <Loader />)
    if(!this.state.isLoading) return (
      <div className="App">
      <main style={(isMobile?theme.mainMobile:theme.main)}>
        <CssBaseline />
        <Paper style={(isMobile?theme.paperMobile:theme.paper)}>
          <img src={logo} className="Login-logo" style={{height:"50vh",maxHeight:"100px"}} alt="logo" />
          <div style={{visibility:(this.state.info!==''?'visible':'hidden'),color:"green",backgroundColor:"rgba(0,200,0,0.2)",borderRadius:'10px',padding:'10px',margin:"5px"}}>{this.state.info}</div>            
          <div style={{visibility:(this.state.error!==''?'visible':'hidden'),color:"red",backgroundColor:"rgba(255,0,0,0.2)",borderRadius:'10px',padding:'10px',margin:"5px"}}>{this.state.error}</div>            
          <div style={{visibility:(this.state.hideAll?'hidden':'visible')}}>
          <form className={"jss4"} style={(isMobile?theme.formMobile:theme.form)} onSubmit={this.handleSubmit}>
            
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">{this.state.lang.password}</InputLabel>
              <Input name="password" type="password" id="password" onChange={this.handleChangePasswordCheckText} value={this.state.passwordCheck} />
            </FormControl>
            
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password2">{this.state.lang.passwordCheck}</InputLabel>
              <Input name="password2" type="password" id="password2" onChange={this.handleChangePasswordText} value={this.state.password} />
            </FormControl>
            
            
             <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{backgroundColor:'#ABFFC1',color:'#135060',textTransform: "none"}}
            >
              Recuperar
            </Button>
            
          </form>
          </div>

            
          

        </Paper>
      </main>
    </div>
    )
  }
}

export default withParams(Recuperar);