import Storage from './Storage';

function Caches(){

    return new Promise(async function(resolve,reject){
        try{
            let cache = await Storage.get('localCache')
            if(typeof cache === 'string') cache = JSON.parse(cache)
            if(typeof cache.lang === 'undefined'){
                cache['lang'] = 'pt_br'
                Storage.set('localCache',cache)
            }
            let token = cache.token
            if(typeof token === 'undefined'){
                token = await Storage.get('token')
            } 
            let language = await fetch("/assets/lang/"+cache.lang+".json?version=1.2")
                        .then(response => {
                            return response.json()
                        });
            resolve({lang:language,cache:cache,token:token})
        } catch(e){
            console.log('cacheError',e)
            reject('error',e)
        }
    })
}


export default Caches;