import React from 'react'
import Modal from 'react-modal'
import Button from '@material-ui/core/Button';
import { FormControlLabel, Slider, Switch } from '@material-ui/core';
import Api from '../Models/Api';
import Storage from '../Models/Storage';
import { isMobile } from 'react-device-detect';

const pjson = require('../version.json');

class ProfileHolder extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        lang:props.lang,
        user:props.user,
        token:props.token,
        isLoading:true,
        isProfileModalOpen:false,
        isMetasModalOpen:false,
        minutes_per_day: ( this.props.user.meta_minutes * 1 ) ,
        days_per_week: ( this.props.user.meta_days * 1 )
      }

      this.handleProfileUpdate = this.handleProfileUpdate.bind(this)
      this.closeModals = this.closeModals.bind(this)
    }

    setMetasModal(status){
      this.setState({isMetasModalOpen:status})
    }

    handleChangeName(event){
      let userdata = this.state.user
      userdata.name = event.target.value
      this.setState({user:userdata})
    }

    async setMinutesPerDay(data){
      await this.setState({minutes_per_day:(data*60000)})
    }
    async setDaysPerWeek(data){
      await this.setState({days_per_week:data})
    }

    checkRanked(){
      const ranked = (this.state.user.ranked===true || this.state.user.ranked==='true')
      return ranked 
    }

    setRanked(){
      let userdata = this.state.user
      userdata.ranked = !this.checkRanked()
      this.setState({user:userdata})
    }

    async handleProfileUpdate(event){
      event.preventDefault();
      let bodyFormData = new FormData()
      bodyFormData.append('meta_days', this.state.days_per_week)
      bodyFormData.append('meta_minutes', this.state.minutes_per_day)
      bodyFormData.append('name', this.state.user.name)
      bodyFormData.append('ranked', this.state.user.ranked)
      Api.post('/update_profile',bodyFormData,{headers: {Version:'PWA_'+pjson.version,'X-Access-Token':this.state.token }}).then(async (response)=>{
        const userData = response.data
        await Storage.set('user',userData)
        this.closeModals()
      }).catch(function (error) {
        console.log('error')
      })
    }

    closeModals(){
      this.props.listFiltering(this.state)
      this.setState({isMetasModalOpen:false})
      this.setState({isProfileModalOpen:false})
    }
    
  render(){
    
    return (
      <>
      
          <Modal
          ariaHideApp={false}
          id="metasModal"
           isOpen={this.state.isMetasModalOpen}       
            onRequestClose={()=> this.setMetasModal(false)}
            contentLabel="Example Modal"
          >
            
            <button className='noButton' style={{position:'absolute',right:"20px",top:"20px"}} onClick={()=> this.setMetasModal(false)}>X</button>
            <form className="row" onSubmit={this.handleProfileUpdate}>
              <div style={{backgroundImage:"url("+this.state.user.picture+")",backgroundSize:"cover",backgroundPosition:"center",margin:'50px',width:"140px",height:"140px",borderRadius:"50%"}} alt={this.state.user.name}></div>
              <div className="col-lg-12">
              <div className="row">
                 <div className="col-6">
              
                  <div className="row">
                   
                    <div className="col-12">
                    <h5 style={{fontFamily:"GothamRoundedBold"}}>{this.state.lang.your_name}:</h5> 
                    <input
                      type="text"
                      className="form-control"
                      style={{border:"none",borderBottom:"1px solid #999",color:"#999",fontFamily:"GothamRoundedBold",fontSize:"20px",padding:"10px",borderRadius:0}}
                      placeholder={this.state.lang.your_name}
                      aria-label={this.state.lang.your_name}
                      onChange={this.handleChangeName}
                      value={this.state.user.name}
                    />
                    </div>
                    <div className="col-12">
                      <div className='divider-40'></div>
                    </div>
                    <div className="col-12" style={{fontFamily:"GothamRoundedBook"}} ><FormControlLabel key={"ranked_holder"} control={<Switch key={"ranked"}  checked={this.checkRanked()} />} onClick={()=>this.setRanked()} /> {this.state.lang.show_ranks}</div>
                        
                    
                  </div>
                </div>
                <div className="col-6 text-center">
                
                <div className="text-center" style={{width:(isMobile?'100%':'60%'),marginLeft:(isMobile?'0':'20%')}}>
                  <h5 style={{fontFamily:"GothamRoundedBold"}}>{this.state.lang.minutes_per_day} {(this.state.minutes_per_day/60000)}</h5>
                  <Slider 
                    aria-label={this.state.lang.minutes_per_day}
                    defaultValue={( this.state.minutes_per_day / 60000 ) }
                    onChange={(e, val) => this.setMinutesPerDay(val)}
                    valueLabelDisplay="auto"
                    step={5}
                    marks
                    min={0}
                    max={60}
                    sx={{
                      color: 'success.main',
                    }}
                  />
                  <div className='divider-40'></div>
                  <h5 style={{fontFamily:"GothamRoundedBold"}}>{this.state.lang.days_per_week} {this.state.days_per_week}</h5>
                  <Slider 
                    aria-label={this.state.lang.days_per_week}
                    defaultValue={this.state.days_per_week}
                    onChange={(e, val) => this.setDaysPerWeek(val)}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={7}
                    sx={{
                      color: 'success.main',
                    }}
                  />
                </div>
                       
                
              </div>
            </div>
            </div>
            <div className="col-lg-12">
              <div className='row'>
                <div className="col-lg-6 text-center">
                  <div className='divider-40'></div>
                  <Button type="submit" className='internalbutton' >{this.state.lang.define}</Button>
                </div>
                <div className="col-lg-6 text-center">
                  <div className='divider-40'></div>
                  <button type="button" className='internalbutton' onClick={()=>{window.location.href = "/sair"}}>{this.state.lang.exit}</button>
                </div>
                </div>
              </div>
            </form>
          </Modal>
      <div className="row">
          <div className='col-lg-12'>
            <div style={{backgroundImage:"url("+this.state.user.picture+")",backgroundSize:"cover",backgroundPosition:"center",margin:'20px 30px',width:"80px",height:"80px",borderRadius:"50%",float:"left"}} alt={this.state.user.name}></div>
            <div className='button' id="profileButton" style={{cursor:"pointer",width:"200px",float:"left",textAlign:"center"}} onClick={()=>this.setMetasModal(true)} >{this.state.lang.updateMetas}</div>
          </div>
      </div>
      </>
    )
    }
   
}
    


export default ProfileHolder;