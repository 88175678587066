import React from 'react';

function AnalyticsData(props){
    // console.log(props)
  return (
    <>
    { (!props.isHome || props.isMobile) && 
    <div style={props.divStyle}>
        <div id="firstMetricHolder">
            <table>
                <tbody>
                <tr>
                    <td style={{minWidth:'6px',heigth:'auto',backgroundColor:props.colors[2],borderRadius:'4px'}}>
                    &nbsp;
                    </td>
                    <td>
                        <p className='MetricResumeNumbers'>{props.metrics.minutesDay} {props.lang.from_comparison} {props.metrics.metaMinutesDay}</p>
                        <small className='MetricResumeSub'>{props.lang.minutesDay}</small>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div id="secondMetricHolder" style={{marginTop:'5px',marginBottom:'5px'}}>
            <table>
                <tbody>
                <tr>
                    <td style={{minWidth:'6px',heigth:'auto',backgroundColor:props.colors[1],borderRadius:'4px'}}>
                    &nbsp;
                    </td>
                    <td>
                        <p className='MetricResumeNumbers'>{props.metrics.minutesWeek} {props.lang.from_comparison} {props.metrics.metaMinutesWeek}</p>
                        <small className='MetricResumeSub'>{props.lang.minutesWeek}</small>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div id="thirdMetricHolder">
            <table>
                <tbody>
                    <tr>
                        <td style={{minWidth:'6px',heigth:'auto',backgroundColor:props.colors[0],borderRadius:'4px'}}>
                        &nbsp;
                        </td>
                        <td>
                            <p className='MetricResumeNumbers'>{props.metrics.daysDone} {props.lang.from_comparison} {props.metrics.metaDays} </p>
                            <small className='MetricResumeSub'>{props.lang.daysDone}</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    }
    { (props.isHome && !props.isMobile) && 
    <div style={props.divStyle}>
        <table>
            <tbody>
            <tr>
                <td>
                    <div id="firstMetricHolder">
                        <table>
                            <tbody>
                            <tr>
                                <td style={{minWidth:'6px',heigth:'auto',backgroundColor:props.colors[2],borderRadius:'4px'}}>
                                &nbsp;
                                </td>
                                <td>
                                    <p className='MetricResumeNumbers'>{props.metrics.minutesDay} {props.lang.from_comparison} {props.metrics.metaMinutesDay}</p>
                                    <small className='MetricResumeSub'>{props.lang.minutesDay}</small>
                                </td>
                                <td>&nbsp; &nbsp; &nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
                <td>
                    <div id="secondMetricHolder" style={{marginTop:'5px',marginBottom:'5px'}}>
                        <table>
                            <tbody>
                            <tr>
                                <td style={{minWidth:'6px',heigth:'auto',backgroundColor:props.colors[1],borderRadius:'4px'}}>
                                &nbsp;
                                </td>
                                <td>
                                    <p className='MetricResumeNumbers'>{props.metrics.minutesWeek} {props.lang.from_comparison} {props.metrics.metaMinutesWeek}</p>
                                    <small className='MetricResumeSub'>{props.lang.minutesWeek}</small>
                                </td>
                                <td>&nbsp; &nbsp; &nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
                <td>
                    <div id="thirdMetricHolder">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{minWidth:'6px',heigth:'auto',backgroundColor:props.colors[0],borderRadius:'4px'}}>
                                    &nbsp;
                                    </td>
                                    <td>
                                        <p className='MetricResumeNumbers'>{props.metrics.daysDone} {props.lang.from_comparison} {props.metrics.metaDays} </p>
                                        <small className='MetricResumeSub'>{props.lang.daysDone}</small>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>        
    </div>
    }
    </>
  );
}

export default AnalyticsData;