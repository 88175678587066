
import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay' 
const AutoplaySlider = withAutoplay(AwesomeSlider)

class Banners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
                  banners:[],
                  routes: {
                          home: 'home',
                          journeys: 'jornadas',
                          pratice: 'suapratica',
                          explore: 'explorar',
                          favorites: 'favoritos',
                  }
                };
  }

  renderSlide(item){
    let href = item.url
    let target = '_self'
    if(item.type==='url') target = '_blank'
    if(item.type==='lesson') href="/pratica/"+item.lesson
    if(item.type==='journey') href="/jornada/"+item.journey
    if(item.type==='route') href="/"+this.state.routes[item.route]
    return (<div style={{backgroundColor:"white",borderRadius:"5px"}}>
              <a href={href} target={target} rel="norefeer" style={{width:'100%',backgroundColor:"white",borderRadius:"5px"}}>
              <div data-src={item.image_desktop} style={{width:'100%',backgroundColor:"white",borderRadius:"5px"}}>
                <img src={item.image_desktop} alt="slide" style={{width:'100%',backgroundColor:"white",borderRadius:"5px"}} />
              </div>
              </a>
            </div>)
  }
  
  render(){    
      if(this.props.banners.length > 0 ) return (  
        <div style={{height:"40vh",marginBottom:"60px",backgroundColor:"white",borderRadius:"5px"}} className={(this.props.isHome?'col-lg-12 col-xs-12 col-sm-12':'row cardList')}>
            <AutoplaySlider cancelOnInteraction={false} interval={5000} style={{backgroundColor:'white',height:"40vh",borderRadius:"5px"}} bullets={true} buttons={false} organicArrows={false} play mobileTouch >
              {this.props.banners.map((item) => this.renderSlide(item) )}
            </AutoplaySlider>
        </div>
    )
    
  }
  
  
}

export default Banners;