import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

const options = {
  plugins: {
    legend: {
      display: false,
      labels: {
        color: "rgb(255, 99, 132)",
        font: {
          family: "GothamRoundedMedium",
          size: 12
        }
      }
    }
  }
  
};

function BarGraph(props){
  ChartJS.register(
                  CategoryScale,
                  LinearScale,
                  BarElement,
                  Title,
                  Tooltip,
                  Legend
                );
  return (
    <div style={props.graphStyle}>
      <h2 className="SessionTitle">{props.title}</h2>
     <Bar options={options} id={props.canvasId} data={props.graphData} />
    </div>
  );
}

export default BarGraph;