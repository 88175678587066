
import React from 'react';
function CategoryHeader(props){
    
    return(
        <div>
            <div style={{padding:'0',margin:'0',width:'100%',backgroundColor:"#efefef",height:'35vh',backgroundImage:"url("+props.category.thumbnail+")",backgroundSize:"contain",backgroundRepeat:"no-repeat",backgroundPosition:"center"}}></div>
            <div style={{padding:'15px'}}>
                <h2>{props.category.name}</h2>
                <small className="GothamRoundedBook" style={{color:"#888"}}>{props.category.description}</small>
                <div style={{marginTop:"20px",marginBottom:"20px"}}>
                <a href={"/categoria/"+props.category.slug} className='button'>{props.lang.lessons}</a> 
            </div>
            </div>
        </div>
    )

}

export default CategoryHeader;