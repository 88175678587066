import logo from '../Assets/logo.png'
import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from 'react-modal'
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Api from '../Models/Api';
import Storage from '../Models/Storage';
import Caches from '../Models/Caches';
import '../Assets/Styles.css'
import backArrow from '../Assets/back.png'

const pjson = require('../version.json');

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: "4vw",
    marginRight: "4vw",
    maxWidth: "100vw",
    [theme.breakpoints.up(400 + "4vw" * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: "10vh",
    margin: "auto auto",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: "none",
    boxShadow: "none",
    width: "40vw",
    [theme.breakpoints.up(400 + "4vw" * 2)]: {
      marginTop: "2vh",
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: "4vw",
    }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main, 
    [theme.breakpoints.up(400 + "4vw" * 2)]: {
      marginTop: "2vh"
    }
  },
  form: {
    width: '20vw', 
    marginTop: "2vh",
    [theme.breakpoints.up(400 + "4vw" * 2)]: {
      marginTop: "2vh",
      width: '80vw'
    }
  },
});

function Login(props) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginChecked, setLoginChecked] = useState(false);
  const [showScreen, setScreen] = useState('login');
  const [loginError, setLoginError] = useState("");
  const [loginInfo, setLoginInfo] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const { classes } = props;
  const [lang, setLang] = useState({});
  // const [isLoading, setIsLoading] = useState(true);
  
  async function checkLogged(){
    if(!loginChecked){
      setLoginChecked(true)
      Caches().then(async (result)=>{
        setLang(result.lang)
      })
      const token = await Storage.get('token')
      if(token!==null){
        return await Api.get('/checkValid',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((userConfirmation)=>{
          if(userConfirmation.data.valid===true){
            window.location.href='/home'
            return true
          }
          goTo('login',0)
          return false;
        }).catch(function (error) {
          goTo('login',0)
          return false
        })
      }
    }
    return false
  }

  function showModal(){
    return isModalOpen;
  }
  
  function goTo(to,time=2000){
    // const href = window.location.href.split('/')
    // if(
    //   href[3]!==to 
    //   && Functions.inArray(border_routes,href[3]) 
    //   && !Functions.inArray(internal_routes,href[3])
    //   ){
    //     window.setTimeout(function(){
    //       window.location.href="/"+to
    //       window.setTimeout(function(){
    //         // setIsLoading(false)
    //       },500)
    //     },time)
    //   } else {
    //     window.setTimeout(function(){
    //       // setIsLoading(false)
    //     },500)
    //   }     
  } 

  function handleSubmit(event) {
    event.preventDefault()
    let bodyFormData = new FormData()
    bodyFormData.append('email', email)
    bodyFormData.append('password', password)
    
    Api.post('/login',bodyFormData,{headers: { "Content-Type": "multipart/form-data" }}).then((response)=>{
      const userData = response.data
      if(userData.success){
        Storage.set('user',userData.user)
        Storage.set('token',userData.auth_token)
        document.querySelectorAll('#zapBtn').forEach(link => {
          link.classList.remove('hidden');
        });
        setLoginInfo('Autenticado com sucesso!')
        window.setTimeout(function(){
          window.location.href='/home'
        },500)
      } else {
        if(userData.message==='SUBSCRIPTION_EXPIRED'){
          setLoginError("Sua inscrição expirou. Entre em contato com oi@cleanyoga.club")
        } else setLoginError(userData.message)
        window.setTimeout(function(){
          setLoginError('')
        },4000)
      }
    }).catch(function (error) {
      setLoginError(error.response.data.message)
      window.setTimeout(function(){
        setLoginError('')
      },4000)
    })
  }

  function handlePassRecovery(event) {
    event.preventDefault();
    let bodyFormData = new FormData()
    bodyFormData.append('email', email)
    setModalOpen(true);
    setEmail("");
    setScreen('login');
    Api.post('/password_recovery_request',bodyFormData,{headers: { "Content-Type": "multipart/form-data" }});
  }

  function closeModal(){
    setModalOpen(false);
  }

  function showThis(screen){
    let styles = {visibility:"hidden",display:"none"}
    if(showScreen===screen){
      styles.visibility = "visible"
      styles.display = "block"
    }
    return styles
  }

  if(!loginChecked){
    checkLogged()
  }
  return (
    <div className="App">
      <main className={classes.main}>
        <CssBaseline />
        <Modal
          ariaHideApp={false}
          id="passwordRecoveryModal"
          centered
          isOpen={showModal()}  
          style={{marginBottom:"10vh"}}
        >
           <button className='noButton' style={{position:'absolute',right:"10px",top:"10px"}} onClick={()=>closeModal()}>X</button>
            <div className="row">
            <div className='divider-40 desktop-hidden'></div>
              <div className="col-lg-12 text-center">
              <h2 style={{marginBottom:"40px"}}>Recebemos sua solicitação de criação / redefinição de senha</h2>
              <h4>⚠️ IMPORTANTE: </h4>
              <p style={{fontSize: "1.25rem",padding: "0 10vw"}}><br/>Verifique a caixa de entrada do seu email (deve ser o mesmo email utilizado na hora da compra) e siga com atenção as orientações para definir sua nova senha e acessar o app.
              <br/><br/>O título do email é <b>[Clean Yoga Club] Recuperação de Senha</b>. Lembrando que a mensagem pode ter caído no seu lixo eletrônico ou spam. Então, certifique-se de verificar lá também.
              </p> 
              </div>
              <div className="col-lg-12 text-center"> 
              <Button style={{textTransform:"none"}} className='button' onClick={()=>closeModal()}>Ok, entendi</Button>
              </div>  
            </div>  
        </Modal>
        <Paper className={classes.paper}>
          <img src={logo} className="Login-logo" style={{height:"50vh",maxHeight:"100px"}} alt="logo" />
          
          <form style={(showThis('login'))} className={classes.form} onSubmit={handleSubmit}>
            <FormControl classes={"fontGothamRoundedBook"} margin="normal" required fullWidth>
              <InputLabel htmlFor="email" className='GothamRoundedBook'>{lang.username}</InputLabel>
              <Input classes={"fontGothamRoundedBook"} id="email" name="email" autoComplete="email" autoFocus onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">{lang.password}</InputLabel>
              <Input name="password" type="password" id="password"  onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            
            <div style={{visibility:(loginError!==''?'visible':'hidden'),color:"red",backgroundColor:"rgba(255,0,0,0.2)",borderRadius:'10px',padding:'10px',margin:"5px"}}>{loginError}</div>            
            <div style={{visibility:(loginInfo!==''?'visible':'hidden'),color:"green",backgroundColor:"rgba(0,200,0,0.2)",borderRadius:'10px',padding:'10px',margin:"5px"}}>{loginInfo}</div>            
            
            <Button
              type="button"
              variant="contained"
              onClick={()=>setScreen('passwordRecovery')}
              style={{fontFamily: "GothamRoundedBook",backgroundColor:'#fffff',color:'#135060',background:"none",border:"none",textDecoration:"underline",boxShadow:"none",marginTop:"3vh",marginBottom:"5vh",textTransform: "none"}}
              >{lang.forgotPassLineOne}
            <br></br>{lang.forgotPassLineTwo}</Button>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{backgroundColor:'#ABFFC1',color:'#135060',textTransform: "none"}}
            >
              Login
            </Button>
            
          </form>

          <form style={(showThis('passwordRecovery'))} className={classes.form} onSubmit={handlePassRecovery}>
            <Button
              type="button"
              variant="contained"
              onClick={()=>setScreen('login')}
              style={{fontFamily: "GothamRoundedBook",backgroundColor:'#fffff',color:'#135060',background:"none",border:"none",textDecoration:"none",boxShadow:"none",float:'left',position: "absolute",left: "0px",top:"20px"}}
              ><img src={backArrow}  style={{width:'1.5rem'}} alt="voltar"/></Button>
            <FormControl classes={"fontGothamRoundedBook"} margin="normal" required fullWidth>
              <InputLabel htmlFor="email" className='GothamRoundedBook'>{lang.username}</InputLabel>
              <Input classes={"fontGothamRoundedBook"} id="email" name="email" autoComplete="email" autoFocus onChange={(e) => setEmail(e.target.value)} value={email} />
            </FormControl>
            <div style={{height:"10vh"}}></div>
            <div style={{visibility:(loginError!==''?'visible':'hidden'),color:"red",backgroundColor:"rgba(255,0,0,0.2)",borderRadius:'10px',padding:'10px',margin:"5px"}}>{loginError}</div>            
            <div style={{visibility:(loginInfo!==''?'visible':'hidden'),color:"green",backgroundColor:"rgba(0,200,0,0.2)",borderRadius:'10px',padding:'10px',margin:"5px"}}>{loginInfo}</div>            
            <div style={{height:"10vh"}}></div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{backgroundColor:'#ABFFC1',color:'#135060',textTransform: "none",marginBottom:"10px"}}
            >
              {lang.textSend}
            </Button>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              style={{backgroundColor:'#ABFFC1',color:'#135060',textTransform: "none"}}
              onClick={()=>setScreen('login')}
            >
              {lang.textBack}
            </Button>

          </form>

        </Paper>
      </main>
    </div>
  );
  
  

}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
