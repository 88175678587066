import { Link } from "react-router-dom";
import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import logo from '../Assets/logo.png'

import cfg from '../Assets/Constants.json'

import homeImage from '../Assets/inicio.png'
import homeImageOn from '../Assets/inicioOn.png'

import exploreImage from '../Assets/explorar.png'
import exploreImageOn from '../Assets/explorarOn.png'

import favImage from '../Assets/favoritos.png'
import favImageOn from '../Assets/favoritosOn.png'

import journeysImage from '../Assets/jornadas.png'
import journeysImageOn from '../Assets/jornadasOn.png'

import praticeImage from '../Assets/suapratica.png'
import praticeImageOn from '../Assets/suapraticaOn.png'

import "react-pro-sidebar/dist/css/styles.css";

import Caches from '../Models/Caches';

class MenuDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
                  activeMenu:props.active,
                  lang:{}
    }
  }

  componentDidMount(){
    Caches().then(async (result)=>{
      this.setState({'lang':result.lang})
      const token = result.token
      return token
    })
  }


  getHomeImage(){
      if(this.state.activeMenu==='home') return homeImageOn
      return homeImage
  }

  getSearchImage(){
      if(this.state.activeMenu==='explorar') return exploreImageOn
      return exploreImage
  }

  getJourneyImage(){
      if(this.state.activeMenu==='jornadas' || this.state.activeMenu==='jornada') return journeysImageOn
      return journeysImage
  }

  getFavoritesImage(){
      if(this.state.activeMenu==='favoritos') return favImageOn
      return favImage
  }

  getPraticeImage(){
      if(this.state.activeMenu==='praticas' || this.state.activeMenu==='pratica' || this.state.activeMenu==='suapratica') return praticeImageOn
      return praticeImage
  }
  
  render(){
  return (
    <>
      <div className="logotext" style={{position:"fixed",zIndex:999,left:0,top:0,right:0,height:"11vh",backgroundColor:"#abffc1"}}>             
        <img src={logo} alt="logo" style={{height:'65%',marginLeft:"2vw",marginTop:"20px"}} />
      </div>
      <div id="header">
        <ProSidebar collapsed={false} style={{marginTop:"11vh"}}>
          
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem key="home"><img src={this.getHomeImage()} alt="img" style={{width:"3vw"}}/>
                <Link onClick={()=> this.setState({"activeMenu":'home'})} to="/home" style={{color:cfg.pallete.darkBlue,fontFamily:(this.state.activeMenu==='home'?"GothamRoundedBold":"GothamRoundedBook")}}> {this.state.lang?.menu?.home}</Link>
              </MenuItem>
              <MenuItem key="search"><img src={this.getSearchImage()} alt="img" style={{width:"3vw"}}/>
                <Link onClick={()=> this.setState({"activeMenu":'explorar'})} to="/explorar" style={{color:cfg.pallete.darkBlue,fontFamily:(this.state.activeMenu==='explorar'?"GothamRoundedLight":"GothamRoundedLight")}}> {this.state.lang?.menu?.explore}</Link>
              </MenuItem>
              <MenuItem key="journeys"><img src={this.getJourneyImage()} alt="img" style={{width:"3vw"}}/>
                <Link onClick={()=> this.setState({"activeMenu":'jornadas'})} to="/jornadas" style={{color:cfg.pallete.darkBlue,fontFamily:(this.state.activeMenu==='jornadas'?"GothamRoundedBold":"GothamRoundedLight")}}> {this.state.lang?.menu?.journey}</Link>
              </MenuItem>
              <MenuItem key="favorites"><img src={this.getFavoritesImage()} alt="img" style={{width:"3vw"}}/>
                <Link onClick={()=> this.setState({"activeMenu":'favoritos'})} to="/favoritos" style={{color:cfg.pallete.darkBlue,fontFamily:(this.state.activeMenu==='favoritos'?"GothamRoundedBold":"GothamRoundedLight")}}> {this.state.lang?.menu?.favorite}</Link>
              </MenuItem>
              <MenuItem key="practice"><img src={this.getPraticeImage()} alt="img" style={{width:"3vw"}}/>
                <Link onClick={()=> this.setState({"activeMenu":'suapratica'})} to="/suapratica" style={{color:cfg.pallete.darkBlue,fontFamily:(this.state.activeMenu==='suapratica'?"GothamRoundedBold":"GothamRoundedLight")}}> {this.state.lang?.menu?.pratice}</Link>
              </MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            Clean Yoga Club&reg;
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
}

}

export default MenuDesktop