

import React from 'react';

import levelZero from '../Assets/level_icon_0.png'
import levelOne from '../Assets/level_icon_1.png'
import levelTwo from '../Assets/level_icon_2.png'
import levelThree from '../Assets/level_icon_3.png'
import levelFour from '../Assets/level_icon_4.png'

const levels = [levelZero,levelOne,levelTwo,levelThree,levelFour]

function LessonLevel(props){
   
  return (
    <div>
        <img src={levels[props.level]} width="18px" height="18px" style={{width:"18px",height:"18px",marginRight:"10px"}} alt="duration" /> {props.lang.levels[props.level]}
    </div>
  );
}

export default LessonLevel;