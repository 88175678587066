import React  from 'react'
import Caches from '../Models/Caches';
import { FaSearch } from 'react-icons/fa'
import Modal from 'react-modal'
import { Button, Switch, FormControlLabel } from '@material-ui/core';
import Api from '../Models/Api';
import Functions from '../Models/Functions'
import clockImage from '../Assets/clock.png'
import tagImage from '../Assets/tag.png'
import { isMobile } from 'react-device-detect';

const pjson = require('../version.json');
class Filters extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
                    isLoading:true,
                    lang:{},
                    categoryList:[],
                    tagList: [],
                    timeList: [],
                    filters:{
                      categories:[],
                      tags:[],
                      duration:[],
                      search_term:''
                    },
                    isCategoriesModalOpen: false,
                    isTagsModalOpen: false,
                    isDurationsModalOpen: false
      }
      this.applyFilter = this.applyFilter.bind(this)
      this.handleChangeText = this.handleChangeText.bind(this)
      this.onKeyUp = this.onKeyUp.bind(this)
    }

    componentDidMount(){
      Caches().then(async (result)=>{
        await this.setState({'lang':result.lang})
        this.setState({"timeList":result.lang.timeList})
        return result.token
      }).then((token)=>{
        Api.get('/categories',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
          this.setState({'categoryList':result.data})
        })
        Api.get('/tags',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
          this.setState({'tagList':result.data})
        })
      })
    }

    handleChangeText(event){
      this.setState({filters:{categories:this.state.filters.categories,tags:this.state.filters.tags,duration:this.state.filters.duration,search_term:event.target.value}})
      if(event.target.value==='') this.applyFilter()
    }

    async applyFilter(){
      await this.setState({isCategoriesModalOpen:false})
      await this.setState({isDurationsModalOpen:false})
      await this.setState({isTagsModalOpen:false})
      this.props.listFiltering(this.state.filters)
    }
    
    checkCategory(id){
      let list = this.state.filters.categories
      if(this.categoryChecked(id)){
        list = list.filter(function(value, index, arr){ 
          return value !== id;
        })
      } else {
        list.push(id)
      }
      this.setState({filters:{categories:list,tags:this.state.filters.tags,duration:this.state.filters.duration,search_term:this.state.filters.search_term}})
    }

    categoryChecked(id){
      return Functions.inArray(this.state.filters.categories,id)
    }

    checkTag(id){
      let list = this.state.filters.tags
      if(this.tagChecked(id)){
        list = list.filter(function(value, index, arr){ 
          return value !== id;
        })
      } else {
        list.push(id)
      }
      this.setState({filters:{categories:this.state.filters.categories,tags:list,duration:this.state.filters.duration,search_term:this.state.filters.search_term}})
    }
    tagChecked(id){
      return Functions.inArray(this.state.filters.tags,id)
    }
    
    checkDuration(id){
      let list = this.state.filters.duration
      if(this.durationChecked(id)){
        list = list.filter(function(value, index, arr){ 
          return value !== id;
        })
      } else {
        list.push(id)
      }
      this.setState({filters:{categories:this.state.filters.categories,tags:this.state.filters.tags,duration:list,search_term:this.state.filters.search_term}})
    }
    durationChecked(id){
      return Functions.inArray(this.state.filters.duration,id)
    }

    onKeyUp(event) {
      if (event.code === 'Enter') {
        this.applyFilter()
      }
    }

    render(){
        return(<div>
          <Modal
          ariaHideApp={false}
          id="categoriesFilterModal"
          centered
           isOpen={this.state.isCategoriesModalOpen}      
          >
            <button className='noButton' style={{position:'absolute',right:"20px",top:"20px"}} onClick={this.applyFilter}>X</button>
            <div className="row">
              <div className="col-lg-12">
              <h2>{this.state.lang.categories}</h2>
              {this.state.categoryList.map((item) => 
                                  <div className='row' style={{marginTop:"10px"}}>
                                    <div className="col-lg-2 mobile-hidden"></div>
                                    <div className="col-lg-3 col-4"><img src={item.thumbnail} className="categoryListImage" alt={item.name} /></div>
                                    <div className="col-lg-3 col-6 categoryKeyFilter">{item.name}</div>
                                    <div className="col-2"><FormControlLabel className='categoryKeyFilter' style={{float:"right"}} key={"category_"+item.id+"_holder"} control={<Switch key={"category_"+item.id}  checked={this.categoryChecked(item.id)} />} onChange={()=>this.checkCategory(item.id)} /></div>
                                  </div>)}
              </div>
              <div className='divider-40'></div>
              <div className="col-lg-12 text-center">
                <Button className='button' onClick={this.applyFilter}>{this.state.lang.apply}</Button>
              </div>
            </div>
          </Modal>


          <Modal
          ariaHideApp={false}
          id="durationsFilterModal"
          centered
           isOpen={this.state.isDurationsModalOpen}  
          >
            <button className='noButton' style={{position:'absolute',right:"20px",top:"20px"}} onClick={this.applyFilter}>X</button>
            <div className="row">
              <div className="col-lg-12">
              <h2 style={{marginBottom:(isMobile?"20px":"40px")}}>{this.state.lang.duration}</h2>
              {this.state.timeList.map((item) => <table style={{marginTop:"10px",width:"100vw"}}>
                                                  <tr className="col-lg-1 col-12 text-center">
                                                    <td style={{width:"10vw"}}><img src={clockImage} width="20px" height="20px" style={{width:"25px;",height:"25px;",marginRight:"10px"}} alt={item.name} /></td>
                                                    <td style={{width:"70vw"}}>{item.name}</td>
                                                    <td style={{width:"20vw"}}><FormControlLabel key={"duration_"+item.id+"_holder"} control={<Switch key={"duration_"+item.id}  checked={this.durationChecked(item.id)} />} onChange={()=>this.checkDuration(item.id)} /></td>
                                                  </tr>
                                                 </table>)}
              </div>
              <div className='divider-40'></div>
              <div className="col-lg-12 text-center">
                <Button className='button' onClick={this.applyFilter}>{this.state.lang.apply}</Button>
              </div>
            </div>
          </Modal>


          <Modal
          ariaHideApp={false}
          id="tagsFilterModal"
          centered
           isOpen={this.state.isTagsModalOpen}  
          >
            <button className='noButton' style={{position:'absolute',right:"20px",top:"20px"}} onClick={this.applyFilter}>X</button>
            <div className="row">
              <div className="col-lg-12">
              <h2 style={{marginBottom:(isMobile?"0px":"40px")}}>{this.state.lang.tags}</h2>
              {this.state.tagList.map((item) => <div className='row' style={{marginTop:"10px"}}>
                                                  <div className="col-lg-4 mobile-hidden"></div>
                                                  <div className="col-lg-1 col-2"><img src={tagImage} width="20px" height="20px" style={{width:"25px;",height:"25px;",marginRight:"10px"}} alt={item.title} /></div>
                                                  <div className="col-lg-2 col-8">{item.title}</div>
                                                  <div className="col-lg-1 col-2"><FormControlLabel style={{float:"right"}} key={"tag_"+item.id+"_holder"} control={<Switch key={"tag_"+item.id}  checked={this.tagChecked(item.id)} />} onChange={()=>this.checkTag(item.id)} /></div>
                                                </div>)}
              
              </div>
              <div className='divider-40'></div>
              <div className="col-lg-12 text-center">
                <Button className='button' onClick={this.applyFilter}>{this.state.lang.apply}</Button>
              </div>
            </div>
          </Modal>
          { isMobile &&
          <>
          <div className='row'>
            <form className="col-12"  onClick={this.applyFilter} >
            <button type="submit" className="searchButton" onClick={this.applyFilter} ><FaSearch /></button>
            <input
              type="text"
              className="searchBox"
              tabIndex="0"
              placeholder={this.state.lang.search_on_cyc}
              aria-label={this.state.lang.search_on_cyc}
              onChange={this.handleChangeText}
              value={this.state.filters.search_term}
            />
               
            <hr></hr>
            </form>
            
            <div className="col-12 text-center">
              <Button className={(this.state.filters.categories.length>0?"filterButtonSelected":"filterButton")} onClick={()=>this.setState({isCategoriesModalOpen:true})}>{this.state.lang.categories}</Button>
              <Button className={(this.state.filters.duration.length>0?"filterButtonSelected":"filterButton")} onClick={()=>this.setState({isDurationsModalOpen:true})}>{this.state.lang.duration}</Button>
              <Button className={(this.state.filters.tags.length>0?"filterButtonSelected":"filterButton")} onClick={()=>this.setState({isTagsModalOpen:true})}>{this.state.lang.tags}</Button>
            </div>
          </div>
          <hr></hr>
          </>
          }
          { !isMobile &&
          <>
          <div className='row'>
            <div className="col-11" style={{paddingLeft:"65px",paddingTop:"35px"}}>
            <button className="searchButton" onClick={this.applyFilter} ><FaSearch /></button>
            <input
              type="text"
              className="searchBox"
              style={{width:"90%"}}
              placeholderTextColor="#999" 
              placeholder={this.state.lang.search_on_cyc}
              aria-label={this.state.lang.search_on_cyc}
              onChange={this.handleChangeText}
              value={this.state.filters.search_term}
              onKeyDown={this.onKeyUp}
            />
          
            <hr></hr>
            </div>
            
            {/* <div className="col-6 text-right" style={{padding:"50px 25px 25px 0px",fontFamily:"GothamRoundedBold",color:"#666"}}>
              Filtros: 
              <Button className={(this.state.filters.categories.length>0?"filterButtonSelected":"filterButton")} onClick={()=>this.setState({isCategoriesModalOpen:true})}>{this.state.lang.categories}</Button>
              <Button className={(this.state.filters.duration.length>0?"filterButtonSelected":"filterButton")} onClick={()=>this.setState({isDurationsModalOpen:true})}>{this.state.lang.duration}</Button>
              <Button className={(this.state.filters.tags.length>0?"filterButtonSelected":"filterButton")} onClick={()=>this.setState({isTagsModalOpen:true})}>{this.state.lang.tags}</Button>
            </div> */}
          </div>
          </>
          }
        </div>)
    }
  
}

export default Filters