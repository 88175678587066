import React  from 'react';
import Caches from '../Models/Caches';
import PracticalResume from '../Components/PracticalResume';
import ProfileHolder from '../Components/ProfileHolder';
import Storage from '../Models/Storage';

class SuaPratica extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang:{},
      user:{meta_days:0,meta_minutes:0},
      token:'',
      isLoading:true
    }
    this.listFiltering = this.listFiltering.bind(this);
  }

  componentDidMount(){
    this.mountData()
  }

  async mountData(state={}){
    this.setState({isLoading:true})
    Caches().then(async (result)=>{
      const userData = await Storage.get('user')
      const token = await Storage.get('token')
      this.setState({lang:result.lang})
      this.setState({user:userData})
      this.setState({token:token})
      window.setTimeout(()=>{
        this.setState({isLoading:false})
      },1000)      
    });
    document.querySelectorAll('#zapBtn').forEach(link => {
      link.classList.remove('hidden');
    });
  }

  async listFiltering(state){
    await this.mountData(state)
  }

  render(){
    if(!this.state.isLoading) return (
      <div>
          <ProfileHolder listFiltering={this.listFiltering} user={this.state.user} lang={this.state.lang} token={this.state.token} />
          <PracticalResume isHome={false} user={this.state.user} withGraphs={true} lang={this.state.lang} />
      </div>
    );
  }
}

export default SuaPratica;
