import React from 'react';
import Favorite from './Favorite';
import { isMobile } from 'react-device-detect';

function JourneysCard(props){
    let className = "col-lg-4 col-sm-12 col-xs-12 lessonCard"
    let innerClass = "lessonInnerCard"
    if(props.lesser===true){
        className = "col-6"
        innerClass = "lessonInnerCard noBorder"
    }
    if(props.first){
        className = "lessonCard"
        innerClass = "lessonInnerCard noBorder"
    }
    let imageStyle ={backgroundColor:"#efefef",backgroundImage:"url("+props.journey.thumbnail+")",backgroundPosition:"center",backgroundSize:"cover",borderRadius: '5px', width:'10rem',height:'10rem'}
    
    if(!isMobile){
        className = "col-4"
        imageStyle.width = '30vw'
        imageStyle.height = '40vh'
    }
    if(props.nolink){
        className = ""
        imageStyle.width = '26vw'
        imageStyle.height = '55vh'
    }
    if(props.filteredDesktop){
        imageStyle.height = '30vh'
        imageStyle.width = '15vw'             
    }
    if(!props.hideme) return (
            <div className={className} >
                <div className={innerClass} style={{width:"auto",height:"auto"}}>
                    { props.nolink!==true &&
                    <a href={'/jornada/'+props.journey.slug} >
                       <div style={imageStyle}></div>
                        <small>{props.lang.journey}</small>
                        <h3>{props.journey.name}</h3>
                        <Favorite 
                                imgStyles={{position:'absolute',top:'10px',left:'10px',width:'20px',height:'20px'}}
                                isFavorite={props.journey.isFavorite} 
                                slug={props.journey.slug} 
                                showNotfav={false}
                                type="journey" 
                        />
                    </a>
                    }
                    { props.nolink===true &&
                    <div>
                        <div style={imageStyle}></div>
                        <small>{props.lang.journey}</small>
                        <h3>{props.journey.name}</h3>
                        <Favorite 
                                imgStyles={{position:'absolute',top:'10px',left:'10px',width:'20px',height:'20px'}}
                                isFavorite={props.journey.isFavorite} 
                                slug={props.journey.slug} 
                                showNotfav={false}
                                type="journey" 
                        />
                    </div>
                    }
                    
                </div>    
            </div>    
    );

}


export default JourneysCard;