import React from 'react';
import Api from '../Models/Api';
import Storage from '../Models/Storage'
import Loader from '../Components/Loader'
import Caches from '../Models/Caches';
import JourneysCard from '../Components/JourneysCard'
import JourneyHeader from '../Components/JourneyHeader';

const pjson = require('../version.json');

class Jornadas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
                  isLoading:true,
                  lessonList:[],
                  activeJourney:0,
                  theActiveJourney:{},
                  lang:{}
    }
  }

  componentDidMount(){
    Caches().then(async (result)=>{
      this.setState({'lang':result.lang})
      const token = await Storage.get('token')
      return token
    }).then(async (token)=>{
      this.getData(token)
    });
    document.querySelectorAll('#zapBtn').forEach(link => {
      link.classList.remove('hidden');
    }); 
  }
  
  getData(token){
    Api.get('/journeys',{headers:{Version:'PWA_'+pjson.version,'X-Access-Token':token}}).then((result)=>{
      this.setState({'journeyList':result.data.journeys})
      this.setState({'activeJourney':result.data.journeys[0].id})
      this.setState({'theActiveJourney':result.data.journeys[0]})
      this.setState({'isLoading':false})
    }).catch(function (error) {
      console.log('Something goes wrong',error)
    })
  }

  changeActiveJourney(item){
    this.setState({'activeJourney':item.id});
    this.setState({'theActiveJourney':item});
    window.scrollTo(0, 0)
  }
  
  render(){
  if(this.state.isLoading) return ( <Loader />)
  if(!this.state.isLoading){
    return (  
        <div>
        <JourneyHeader lang={this.state.lang} journey={this.state.theActiveJourney} />
          <div  className="row cardList">
          {this.state.journeyList.map((item) => <div key={item.id+"_holder"} style={{visibility:(this.state.activeJourney===item.id?"hidden":"visible"),display:(this.state.activeJourney===item.id?"none":"block")}} className="col-4" onClick={()=>{this.changeActiveJourney(item)}}><JourneysCard lang={this.state.lang} hideme={(this.state.activeJourney===item.id)} key={item.id} journey={item} showNames={true} lesser={true} nolink={true} /></div>)}
          </div>
        </div>
    )
  }
  
  }
}

export default Jornadas;