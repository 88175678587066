import React from 'react';
import Storage from '../Models/Storage';

async function Sair(props) {


    await Storage.clear();
    
    window.setTimeout(function(){window.location.href='/'},1000)
            
    return (<div>Saindo...</div>);
   

}

export default Sair;
