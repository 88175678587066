import React from 'react';

import logo from '../Assets/loader.gif'

function Miniloader(props){
  return (
    <div style={{backgroundColor:"#ffffff",zIndex:1999}}>
        <header className="Miniloader text-center" style={{height:"30vh"}}>
        <img src={logo} className="App-logo" alt="logo" />
        {props.lang.loading}
        </header>
  </div>
  );
}

export default Miniloader;